import React, { useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import { Tag, Space, Divider, Button as AntButton } from 'antd';
import InputField from '../../Common/Input/Input';
import Button from '../../Common/Button/Button';
import './style.scss';
import uuid from '../../../Utility/uniqueId';

import {
    isEmpty,
    isNotEmptyValidText,
    isValidURL,
    isValidEmail,
    hasSpaces
} from '../../../Utility/validation';
import { sanitizeInput } from '../../../Utility/helper';
import Strings from '../../../Common/Constants';

const reducer = (currentState, newState) => ({ ...currentState, ...newState });

const initalState = {
    name: '',
    displayName: '',
    role: '',
    roleError: '',
    nameError: '',
    displayError: '',
    roles: [],
    isDisabled: false,
    isAddNewRoleDisabled: true,
    formErrorMessage: '',
    emailSubject: '',
    emailSubjectError: '',
    emailBody: '',
    emailBodyError: '',
    emailUrl: '',
    emailUrlError: '',
    buId: '',
    rolesId: '',
    emailInBuId: '',
    emailFrom: '',
    emailFromError: ''
};

const AddPractice = ({ onCancel, updateTabList, type, buData, Get, Post }) => {
    const [state, setState] = useReducer(reducer, initalState);
    const {
        name,
        displayName,
        isDisabled,
        role,
        roles,
        roleError,
        nameError,
        displayError,
        isAddNewRoleDisabled,
        formErrorMessage,
        emailSubject,
        emailSubjectError,
        emailBody,
        emailBodyError,
        emailUrl,
        emailUrlError,
        buId,
        rolesId,
        emailInBuId,
        emailFrom,
        emailFromError
    } = state;

    const isEditing = type === 'edit';

    const handleInputChange = (event) => {
        const inputElement = event.target;
        let { value } = inputElement;
        const key = inputElement.getAttribute('datakey');

        setState({ ...state, [key]: value });
    };

    const roleInputChange = (event) => {
        let { value } = event.target;
        handleInputChange(event);
        if (isEmpty(value)) {
            setState({ isAddNewRoleDisabled: true });
        } else {
            setState({ isAddNewRoleDisabled: false });
        }
    };

    const handleAddRole = () => {
        const isRoleAlreadyExist = roles.some(
            (item) => sanitizeInput(item.name || item.role) === sanitizeInput(role)
        );
        const isRoleInValid = isEmpty(role) || hasSpaces(role);

        if (isRoleInValid) {
            setState({ roleError: Strings.ROLE_VALID_ERROR });
            return;
        }

        if (isRoleAlreadyExist) {
            setState({ roleError: Strings.ROLE_EXISTS_ERROR });
            return;
        }

        const id = uuid('id_');

        setState({
            role: '',
            isDisabled: false,
            roleError: '',
            roles: [...state.roles, { name: role, id }]
        });
    };

    const removeRole = (roleId) => {
        const filterRoles = roles.filter(
            (practiceRole) => practiceRole.id !== roleId
        );
        setState({ roles: filterRoles });
    };

    const handlePracticeNameBlur = () => {
        if (!isNotEmptyValidText(name) || hasSpaces(name)) {
            setState({ nameError: Strings.NAME_VALID_ERROR });
        } else {
            Get(
                `/businessunit/checkUnitName/${sanitizeInput(name)}`, new URLSearchParams(), {}
            ).then(
                () => {
                    setState({ nameError: '' });
                }
            ).catch(error => {
                setState({ nameError: 'Name already exists.' });
            });
        }
    };

    const validateInputs = () => {
        let isValid = true;

        if (!isNotEmptyValidText(name)) {
            setState({ nameError: Strings.NAME_VALID_ERROR });
            isValid = false;
        }

        if (isEmpty(displayName)) {
            setState({ displayError: Strings.DISPLAY_NAME_REQUIRED_ERROR });
            isValid = false;
        }

        if (!roles.length) {
            setState({ roleError: Strings.ROLE_REQUIRED_ERROR });
            isValid = false;
        }

        if (isEmpty(emailSubject)) {
            setState({ emailSubjectError: Strings.EMAIL_SUBJECT_REQUIRED_ERROR });
            isValid = false;
        }

        if (isEmpty(emailBody)) {
            setState({ emailBodyError: Strings.EMAIL_BODY_REQUIRED_ERROR });
            isValid = false;
        }

        if (isEmpty(emailUrl) || !isValidURL(emailUrl)) {
            const errorMessage = isEmpty(emailUrl)
                ? Strings.EMAIL_URL_REQUIRED_ERROR
                : isValidURL(emailUrl)
                    ? ''
                    : Strings.EMAIL_URL_INVALID_ERROR;
            setState({ emailUrlError: errorMessage });
            isValid = false;
        }

        if (isEmpty(emailFrom) || !isValidEmail(emailFrom)) {
            setState({ emailFromError: Strings.EMAIL_REQUIRED_ERROR });
            isValid = false;
        }

        return isValid;
    };

    const createData = () => {
        let dateTime = new Date();
        let data = {
            DisplayName: sanitizeInput(displayName),
            Name: sanitizeInput(name),
            McKbusinessUnitRoles: roles.map((item) => ({
                Role: sanitizeInput(item.name || item.Role || item.role),
                CreatedBy: localStorage.getItem("email"),
                CreatedDate: dateTime
            })),
            McKbusinessUnitEmails: [
                {
                    FromEmail: sanitizeInput(emailFrom),
                    Subject: sanitizeInput(emailSubject),
                    Body: sanitizeInput(emailBody),
                    LandingPageInBody: sanitizeInput(emailUrl),
                    CreatedBy: localStorage.getItem("email"),
                    CreatedDate: dateTime
                }
            ],
            CreatedBy: localStorage.getItem("email"),
            CreatedDate: dateTime
        };

        if (isEditing) {
            const newRoles = roles.filter(item => item.id !== rolesId);
            data = {
                ...data,
                Id: buId,
                McKbusinessUnitRoles: newRoles.map((item) => ({
                    Role: sanitizeInput(item.name || item.Role),
                    CreatedBy: localStorage.getItem("email"),
                    CreatedDate: dateTime
                })),
                McKbusinessUnitEmails: [
                    {
                        ...data.McKbusinessUnitEmails[0],
                        Id: emailInBuId
                    }
                ]
            };
        }

        return data;
    };

    const addPractice = () => {
        if (!validateInputs()) {
            return;
        }

        let data = createData();

        setState({ isDisabled: true });
        const headers = {
            "content-type": "application/json;",
        };
        Post('businessunit', data, headers).then(
            () => {
                /* Todo show record created successfully message */
                onCancel();
                updateTabList();
            }
        ).catch((error) => {
            error.text().then(details => {
                let errorDetails = JSON.parse(details);
                console.log(errorDetails.message)
                setState({
                    formErrorMessage: errorDetails.message
                });
            })
        });
        setState({ isDisabled: false });
    };
    
    useEffect(() => {
        if (buData) {
            setState({
                buId: buData?.id,
                name: buData?.name,
                displayName: buData?.displayName,
                roles: buData?.McKBusinessUnitRoles,
                emailInBuId: buData.emailInBusinessUnit[0]?.id || '',
                emailSubject: buData.emailInBusinessUnit[0]?.subject || '',
                emailBody: buData.emailInBusinessUnit[0]?.body || '',
                emailUrl: buData.emailInBusinessUnit[0]?.landingPageInBody || '',
                emailFrom: buData.emailInBusinessUnit[0]?.fromEmail || '',
                rolesId: buData?.McKBusinessUnitRoles[0]?.id,
            });
        }
    }, []);

    const getTagRoles = (practiceRole) => {
        if (practiceRole.Role.indexOf(',') === -1) {
            return (
                <Tag className="role" key={practiceRole.Id}>
                    {practiceRole.Role}
                </Tag>
            );
        } else {
            const roles = practiceRole.Role.split(',');
            return roles.map((role, index) => (
                <Tag className="role" key={`${practiceRole.Id + role}`}>
                    {role}
                </Tag>
            ));
        }
    };

    return (
        <div className="add-practice">
            <p className="error">{formErrorMessage}</p>
            <InputField
                labelText={Strings.NAME_LABEL}
                placeHolder={Strings.NAME_PLACEHOLDER_TEXT}
                errMsg={nameError}
                value={name}
                onBlur={handlePracticeNameBlur}
                disabled={isEditing}
                onChange={handleInputChange}
                dataKey="name"
                isRequired
                reqValidationMessage={Strings.NAME_VALID_ERROR}
            />
            <InputField
                labelText={Strings.DISPLAY_NAME_LABEL}
                placeHolder={Strings.DISPLAY_NAME_PLACEHOLDER_TEXT}
                onChange={handleInputChange}
                errMsg={displayError}
                value={displayName}
                isRequired
                disabled={isEditing}
                dataKey="displayName"
                reqValidationMessage={Strings.DISPLAY_NAME_REQUIRED_ERROR}
            />
            <InputField
                labelText={Strings.CUSTOM_ROLE_LABEL}
                placeHolder={Strings.CUSTOM_ROLE_PLACEHOLDER_TEXT}
                value={role}
                errMsg={roleError}
                onChange={roleInputChange}
                isRequired

                dataKey="role"
                suffix={
                    <AntButton
                        type="link"
                        onClick={handleAddRole}
                        disabled={isAddNewRoleDisabled}
                    >
                        {Strings.ADD_NEW_ROLE_LABEL}
                    </AntButton>
                }
            />
            <div>
                {roles.map((practiceRole) =>
                    practiceRole.id === rolesId ? <Tag
                        className="role"
                        key={practiceRole.id}
                    >
                        {practiceRole.role}
                    </Tag> : (
                        <Tag
                            className="role"
                            key={practiceRole.id}
                            closable
                            onClose={() => removeRole(practiceRole.id)}
                        >
                            {practiceRole.name}
                        </Tag>
                    )
                )}
            </div>
            <div>
                <InputField
                    labelText={Strings.EMAIL_FROM_LABEL}
                    placeHolder={Strings.EMAIL_FROM_LABEL}
                    isRequired
                    errMsg={emailFromError}
                    fieldType="email"
                    value={emailFrom}
                    dataKey="emailFrom"
                    onChange={handleInputChange}
                    reqValidationMessage={Strings.EMAIL_REQUIRED_ERROR}
                    fieldValidationMessage={Strings.EMAIL_ADDRESS_INVALID_ERROR}
                />
                <InputField
                    labelText={Strings.EMAIL_SUBJECT_LABEL}
                    placeHolder={Strings.EMAIL_SUBJECT_LABEL}
                    onChange={handleInputChange}
                    errMsg={emailSubjectError}
                    value={emailSubject}
                    isRequired
                    dataKey="emailSubject"
                    reqValidationMessage={Strings.EMAIL_SUBJECT_REQUIRED_ERROR}
                />
                <InputField
                    labelText={Strings.EMAIL_BODY_LABEL}
                    rows={3}
                    onChange={handleInputChange}
                    errMsg={emailBodyError}
                    value={emailBody}
                    isRequired
                    dataKey="emailBody"
                    multiline
                    reqValidationMessage={Strings.EMAIL_BODY_REQUIRED_ERROR}
                />
                <InputField
                    labelText={Strings.EMAIL_URL_LABEL}
                    placeHolder={Strings.EMAIL_URL_LABEL}
                    onChange={handleInputChange}
                    errMsg={emailUrlError}
                    value={emailUrl}
                    isRequired
                    dataKey="emailUrl"
                    reqValidationMessage={Strings.EMAIL_URL_REQUIRED_ERROR}
                    fieldType="url"
                    fieldValidationMessage={Strings.EMAIL_URL_INVALID_ERROR}
                />
            </div>
            <div>
                <Divider />
                <Space size="middle">
                    <Button
                        type={isEditing ? 'update' : type}
                        isDisabled={isDisabled}
                        clickHandler={addPractice}
                        isLoading={isDisabled}
                    />
                    <AntButton type="primary" onClick={onCancel}>
                        {Strings.CANCEL_BUTTON_LABEL}
                    </AntButton>
                </Space>
            </div>
        </div>
    );
};

AddPractice.defaultProps = {
    type: 'Create'
};

AddPractice.propTypes = {
    onCancel: PropTypes.func.isRequired,
    updateTabList: PropTypes.func.isRequired
};

export default AddPractice;
