import React, { useState, useEffect } from 'react';
import "../../css/EditUser.css";
import Subscriptions from './Subscriptions';
import LoadingSpinner from '../Common/LoadingSpinner';
import NavigationPreferences from './NavigationPreferences';
import { Alert, CustomDialog, useDialog } from 'react-st-modal';
import { useHistory } from "react-router-dom";


export default function EditUser(props) {
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [businessFunction, setBusinessFunction] = useState("");
    const [country, setCountry] = useState("");
    const [email, setEmail] = useState("");
    const [confirmEmail, setConfirmEmail] = useState("");
    const [memberSince, setMemberSince] = useState("");
    const [subscriptionDate, setSubscriptionDate] = useState("");
    const [lastModifiedDate, setLastModifiedDate] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmNewPassword, setConfirmNewPassword] = useState("");
    const [jobTitle, setJobTitle] = useState("");
    const [companySize, setCompanySize] = useState("");
    const [company, setCompany] = useState("");
    const [userEmailPref, setUserEmailPref] = useState("");
    const [displayName, setDisplayName] = useState("");
    const [rbClient, setRBClient] = useState(false);
    const [epsilonEntry, setEpsilonEntry] = useState(false);
    const [ksrEntry, setKsrEntry] = useState(false);
    const [epsilonwelcomemail, setEpsilonWelcomeMail] = useState(false);

    const [originOfAccountCreation, setOriginOfAccountCreation] = useState(false);
    const [companySizes, setCompanySizes] = useState([]);
    const [jobTitles, setJobTitles] = useState([]);
    const [businessUnits, setBusinessUnits] = useState([]);
    const [userRoles, setUserRoles] = useState([]);
    const [countrys, setCountrys] = useState([]);
    const [businessFunctions, setBusinessFunctions] = useState([]);
    const [userSubscriptions, setUserSubscriptions] = useState([]);
    const [disablePasswordResetClass, setDisablePasswordResetClass] = useState("disableControl");
    const [globalOptOutAt, setGlobalOptOutAt] = useState(false);
    const [isDataLoaded, setIsDataLoaded] = useState(false);
    const [disableControlsClass, setDisableControlsClass] = useState("disableControl");
    const [subscriptions, setSubscriptions] = useState({});
    const [navigationPreferences, setNavigationPreferences] = useState({});
    const [selectedNavPrefs, setSelectedNavPrefs] = useState([]);
    const [metadata, setMetadata] = useState({});
    const [isPasswordInInValid, setIsPasswordInInValid] = useState(false);
    const [isEmailInInValid, setIsEmailInInValid] = useState(false);
    const [passwordMismatch, setPasswordMismatch] = useState(false);
    const [emailMismatch, setEmailMismatch] = useState(false);
    const [isPageReloadRequired, setIsPageReloadRequired] = useState(false);
    const [globalError, setGlobalError] = useState("");
    const [isActive, setIsActive] = useState(true);

    let roleId = localStorage.getItem("roleId");
    let buDivClassName = "formLine";

    const adminTaggedPractices = localStorage.getItem("practiceIds");

    //Hide BU section for all Admins and for practice admins that are not tagged to any BU.
    if (roleId === '1' || (roleId === '2' && !adminTaggedPractices)) {
        buDivClassName = "formLine Hide";
    }

    let history = useHistory();

    useEffect(() => {
        let urlSearchParams = new URLSearchParams();
        urlSearchParams.append("id", props.match.params.id);
        let headers = {};

        const getMetadata = props.Get('/metadata?', urlSearchParams, headers);

        const getUserByIdPromise = props.Get('/user/GetUserById?', urlSearchParams, headers);

        Promise.all([getMetadata, getUserByIdPromise]).then(values => {

            let metadata = values[0];
            setMetadata(metadata);
            let userDetails = values[1];

            // For Practice Admin, all password update only if the user being edited is assigned a role from any of the BUs to which practice admin is tagged. 
            if (roleId === '2') {
                userDetails?.sitecoreRoles?.every(role => {
                    const buName = role.split("\\")[0];
                    let buDetails = metadata.businessUnits.filter(item => item.name.toUpperCase() === buName.toUpperCase());
                    let buId = '-1'
                    if (buDetails && buDetails.length > 0) {
                        buId = buDetails[0].id
                    }
                    if (adminTaggedPractices.includes(buId)) {
                        setDisablePasswordResetClass("");
                        return false;
                    }
                    return true;
                });
            }
            else {
                setDisablePasswordResetClass("");
                if (userDetails.registered) {
                    setDisableControlsClass("");
                }
            }
            if (userDetails.subscriptions) {
                setUserSubscriptions(userDetails.subscriptions);
            }

            if (metadata) {
                setSubscriptions(metadata.subscriptionData.subscriptions);
                setNavigationPreferences(metadata.navigationItems.navigationItems);
                populateTitles(metadata);
                populateAvailableCompanySizes(metadata);
                generateBUCheckboxes(userDetails, metadata);
                countryList(metadata);
                bFunctionList(metadata);
            }
            populateFormFields(userDetails);
            setIsDataLoaded(true);
        }).catch((error) => {
            setIsDataLoaded(true);
            setGlobalError("Couldn't fetch user details. Please reach out to your system admin for help.");
        })
    }, [])

    // Generate UI for BU section.
    let generateBUCheckboxes = (userDetails, metadata) => {

        // Map containing all BU and roles assigned to the user.
        //Key: BU Name
        //Value: Array of roles
        let roleBUMap = {};

        // Add all user roles to the specific BU key in the map. 
        userDetails.sitecoreRoles?.forEach(role => {
            let bURoleArray = role.split('\\');

            if (bURoleArray.length > 1) {
                (bURoleArray[0] in roleBUMap) ? roleBUMap[bURoleArray[0]].push(bURoleArray[1].toLowerCase()) : roleBUMap[bURoleArray[0]] = [bURoleArray[1].toLowerCase()];
            }
        });

        let roles = userDetails.sitecoreRoles
        setUserRoles(roles);
        setBusinessUnits(metadata.businessUnits.map(unit => {
            let buDivClasses = "divRolesinBU";

            // For Practice Admin, display only BUs to which the admin is tagged and Hide all other BUs.
            if (roleId === "2" && !adminTaggedPractices.includes(unit.id)) {
                buDivClasses += " Hide";
            }

            return (
                <div className={buDivClasses}>
                    <span className="lblRoleInBU">{unit.displayName}</span>
                    <div className="dlRolesInPractices">
                        {
                            unit.businessUnitRoles.map(role => {
                                let unitNameLowerCase = unit.name.toLowerCase();
                                let sanitizedRoleName = role.role.replace(/\s/g, '').toLowerCase();

                                // Set unit as selected if it is contained in the user sitecore roles.                              
                                let isUnitSelected = (unitNameLowerCase in roleBUMap) && roleBUMap[unitNameLowerCase].includes(sanitizedRoleName) ? true : false;

                                // Handle to selection/unselection of role checkboxes.
                                const handleChange = (e) => {
                                    isUnitSelected = !isUnitSelected;
                                    let currentSiteCoreRoles = [...userRoles];
                                    document.getElementById(unitNameLowerCase + sanitizedRoleName).checked = isUnitSelected;

                                    setUserRoles(function updateunits(currentSiteCoreRoles) {
                                        if (!currentSiteCoreRoles) {
                                            currentSiteCoreRoles = [];
                                        }
                                        let siteCoreRoleName = unitNameLowerCase + "\\" + sanitizedRoleName;
                                        const index = currentSiteCoreRoles.indexOf(siteCoreRoleName);
                                        if (isUnitSelected && index === -1) {
                                            currentSiteCoreRoles.push(siteCoreRoleName);
                                        }
                                        else if (!isUnitSelected && index > -1) {
                                            currentSiteCoreRoles.splice(index, 1);
                                        }
                                        return [...currentSiteCoreRoles];
                                    });
                                };

                                return (
                                    <div className="eachRoleInPractice">
                                        <input id={unitNameLowerCase + sanitizedRoleName} type="checkbox" className={"roleCheckBox"} defaultChecked={isUnitSelected} onChange={(e) => { handleChange(e) }} />
                                        <span>{role.role.trim()}</span>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            )
        }))
    }
    let populateFormFields = (userDetails) => {
        setFirstName(userDetails.firstName);
        setCompany(userDetails.company);
        setCountry(userDetails.country);
        setBusinessFunction(userDetails.businessFunction);
        setLastName(userDetails.lastName);
        setMemberSince(userDetails.registeredAt);
        setEmail(userDetails.email);
        setConfirmEmail(userDetails.email);
        setSubscriptionDate(userDetails.subscribedAt);
        setGlobalOptOutAt(userDetails.globalOptOut);
        setJobTitle(userDetails.title);

        if (userDetails.metadata) {
            setLastModifiedDate(userDetails.updatedAt);
            let userMetadata = userDetails.metadata;
            if (userMetadata.csize) {
                setCompanySize(userMetadata.csize);
            }
            if (userMetadata.epref) {
                setUserEmailPref(userMetadata.epref)
            }
            if (userMetadata.displayname) {
                setDisplayName(userMetadata.displayname)
            }
            if (userMetadata.exclusiveaccess) {
                let exclusiveAccess = userMetadata.exclusiveaccess === 1;
                setRBClient(exclusiveAccess);
            }
            if (userMetadata.epsilonentry) {
                let epsilonEntry = userMetadata.epsilonentry === 1;
                setEpsilonEntry(epsilonEntry);
            }
            if (userMetadata.ksrentry) {
                let ksrentry = userMetadata.ksrentry === 1;
                setKsrEntry(ksrentry);
            }
            if (userMetadata.epsilonwelcomemail) {
                let epsilonwelcomemail = userMetadata.epsilonwelcomemail === 1;
                setEpsilonWelcomeMail(epsilonwelcomemail);
            }
            if (userMetadata.src) {
                let registrationSourceSearchParam = new URLSearchParams();
                registrationSourceSearchParam.append("id", userMetadata.src);
                props.Get('/metadata/GetRegistrationSource?', registrationSourceSearchParam, {}).then(data => {
                    setOriginOfAccountCreation(data.registrationSource);
                })
            }
        }
    }

    let populateAvailableCompanySizes = (metadata) => {
        setCompanySizes(metadata.companySizes.map(size => {
            return (
                <option key={size.id} value={size.id}>{size.name}</option>
            );
        }));
    }

    let populateTitles = (metadata) => {
        setJobTitles(metadata.titles.map(title => {
            return (
                <option key={title.id} value={title.id}>{title.name}</option>
            );
        }));
    }

    let countryList = (metadata) => {
        setCountrys(metadata.countryData.map(country => {
            return (
                <option key={country.code} value={country.code}>{country.description}</option>
            );
        }));
    }

    let bFunctionList = (metadata) => {
        setBusinessFunctions(metadata.bFunctionData.map(func => {
            return (
                <option key={func.code} value={func.code}>{func.description}</option>
            );
        }));
    }
    const toggleActive = () => {
        setIsActive(!isActive);
    };
    // Form field Validations
    const validateFields = () => {
        let errorsOnPage = false;
        if (newPassword) {
            let passwordPattern = new RegExp(/^(?!\s)(?=.*\d)(?=.*[a-zA-Z]).{8,82}(?<!\s)$/);
            let isValidPassword = passwordPattern.test(newPassword);
            if (!isValidPassword) {
                errorsOnPage = true;
                setIsPasswordInInValid(true)
                window.scrollTo(0, 0);
            }
            else {
                setIsPasswordInInValid(false)
            }
        }
        if ((newPassword || confirmNewPassword) && newPassword !== confirmNewPassword) {
            errorsOnPage = true;
            setPasswordMismatch(true);
            window.scrollTo(0, 0);
        }
        else {
            setPasswordMismatch(false);
        }

        if (email && !String(email).toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
            errorsOnPage = true;
            setIsEmailInInValid(true);
            window.scrollTo(0, 0);
        }
        if (email !== confirmEmail) {
            errorsOnPage = true;
            setEmailMismatch(true);
            window.scrollTo(0, 0);
        }

        errorsOnPage = validateOptionFields();
       
        return errorsOnPage;
    }

    function validateOptionFields()
    {
        let inValid = false;
        if (country === null || country === "") {
            inValid = true;
            setGlobalError("Couldn't update user. Please update country");
            window.scrollTo(0, 0);
        }
        if (businessFunction === null || businessFunction === "") {
            inValid = true;
            setGlobalError("Couldn't update user. Please update business function");
            window.scrollTo(0, 0);
        }
        if (!jobTitle) {
            inValid = true;
            setGlobalError("Couldn't update user. Please update job title");
            window.scrollTo(0, 0);
        }
        return inValid;
    }

    // Custom Dialog for displayin the confirmation message before updating the user details, and then making API call to update user details.
    function CustomDialogContent() {
        // use this hook to control the dialog
        const dialog = useDialog();

        const [value, setValue] = useState();
        const [dialogLoading, setDialogLoading] = useState(false);

        return (
            <div>

                <div className="Modal-module_modalContent__nuwvF"> Do you want save changes to this account?</div>
                <div className="Modal-module_modalButtonPlace__AYeOm">
                    <button className="Button-module_--primary__1ZLkB Button-module_button__3FnXy"
                        onClick={() => {
                            // Сlose the dialog and return the value
                            setDialogLoading(true);

                            let selectedRoleIds = [];
                            metadata.businessUnits.forEach(unit => {
                                unit.businessUnitRoles.forEach(role => {
                                    let sanitizedRoleName = role.role.replace(/\s/g, '').toLowerCase();
                                    let currentRoleCount = userRoles?.filter(item => {
                                        let currentRole = item.split('\\')[1];
                                        return currentRole === sanitizedRoleName;
                                    })
                                    if (currentRoleCount && currentRoleCount.length > 0) {
                                        selectedRoleIds.push(role.id);
                                    }
                                })
                            })
                            let userDetails = {
                                'id': props.match.params.id,
                                'firstName': firstName,
                                'company': company,
                                'country': country,
                                'businessFunction': businessFunction,
                                'lastName': lastName,
                                'title': jobTitle,
                                'email': email,
                                'password': newPassword,
                                'ConfirmationPassword': confirmNewPassword,
                                'metadata': {
                                    'epref': userEmailPref,
                                    'csize': companySize,
                                    'exclusiveaccess': rbClient ? 1 : 0,
                                    'practices': [],
                                    'epsilonEntry': epsilonEntry ? 1 : 0,
                                    'ksrEntry': ksrEntry ? 1 : 0,
                                    'epsilonwelcomemail': epsilonwelcomemail ? 1 : 0
                                },
                                'subscriptions': userSubscriptions,
                                'globalOptOut': globalOptOutAt,
                                'siteCoreRole': {
                                    'Roles': userRoles,
                                    'CreatedBy': "",
                                    'UpdatedBy': localStorage.getItem("email")
                                },
                                'prefs': {
                                    'navigationItemIDs': selectedNavPrefs
                                },
                                'selectedRoleIds': selectedRoleIds
                            }
                            const headers = {
                                "content-type": "application/json;",
                            };
                            props.Post('/user/UpdateUser', userDetails, headers).then(
                                response => {
                                    setDialogLoading(false);
                                    dialog.close(value);
                                    Alert('Changes for user have been saved.', 'Save changes');

                                }).catch((error) => {
                                    setDialogLoading(false);
                                    setGlobalError("Couldn't update user. Please try again and if the problem persists, please reach out to your system admin");
                                    dialog.close(value);
                                    window.scrollTo(0, 0);
                                });
                        }}
                    >
                        {
                            dialogLoading ? (<LoadingSpinner isButtonSpinner={true} />) : (<span>Ok</span>)
                        }
                    </button>
                    <button className=" Button-module_--light__3HiwO Button-module_button__3FnXy "
                        onClick={() => {
                            dialog.close(value);
                        }}
                    >
                        Cancel
                    </button>
                </div>
            </div>
        );
    }

    function DeleteUserAlert() {
        // use this hook to control the dialog
        const dialog = useDialog();

        const [value, setValue] = useState();
        const [dialogLoading, setDialogLoading] = useState(false);

        return (
            <div>

                <div className="Modal-module_modalContent__nuwvF"> Are you sure you want to delete this account?</div>
                <div className="Modal-module_modalButtonPlace__AYeOm">
                    <button className="Button-module_--primary__1ZLkB Button-module_button__3FnXy"
                        onClick={() => {
                            // Сlose the dialog and return the value
                            setDialogLoading(true);
                            const headers = {
                                "content-type": "application/json;",
                            };

                            props.Post(`/user/deleteuser/${props.match.params.id}`, {}, headers).then(
                                response => {
                                    setDialogLoading(false);
                                    dialog.close(value);
                                    Alert('Account has been deleted.', 'Delete account').then((response) => {
                                        history.push('/home');
                                    });
                                }).catch((error) => {
                                    setDialogLoading(false);
                                    setGlobalError("Couldn't update user. Please try again and if the problem persists, please reach out to your system admin");
                                    dialog.close(value);
                                    window.scrollTo(0, 0);
                                });
                        }}
                    >
                        {
                            dialogLoading ? (<LoadingSpinner isButtonSpinner={true} />) : (<span>Ok</span>)
                        }
                    </button>
                    <button className=" Button-module_--light__3HiwO Button-module_button__3FnXy "
                        onClick={() => {
                            dialog.close(value);
                        }}
                    >
                        Cancel
                    </button>
                </div>
            </div>
        );
    }

    // Custom Dialog for displayin the confirmation message before deleting the user, and then making API call to delete the user.
    const deleteUser = async () => {
        await CustomDialog(
            <DeleteUserAlert />,
            {
                title: 'Delete account',
                showCloseIcon: false,

            }
        );
    }

    const handleSubmit = async () => {
        setIsPageReloadRequired(true);
        if (!validateFields()) {
            setGlobalError("");
            await CustomDialog(
                
                <CustomDialogContent />,
                {
                    title: 'Save Changes',
                    showCloseIcon: false,
                }
            );
        }
    }

    if (!isDataLoaded) return <LoadingSpinner />;

    return (
        <>
            <div className="delete-account container">
                <input type="submit" name="btnDeleteAccount" value="Delete account" onClick={() => { deleteUser() }} id="btnDeleteAccount" />
            </div>
            <div className="edit-user-wrapper container">

                <div className="accordion">
                    <div className="accordion-item">
                        <div className="accordion-title" onClick={toggleActive} onKeyDown={toggleActive}>
                            <div><h4>Review user's account information</h4></div>
                            <div className="accordion-expand-symbol">{isActive ? '-' : '+'}</div>
                        </div>

                        {
                            isActive && <div className="accordion-content">
                                {
                                    globalError &&
                                    <div className="search-emptyfield-error">
                                        <span className="error-red-icon"></span>
                                        <span>{globalError}</span>
                                    </div>
                                }
                                <div className="formLine">
                                    <label id="lblUniqueId" htmlFor="lblUniqueId">Unique ID</label>
                                    <div className="unchanged-value">
                                        <span id="lblUIdValue">{props.match.params.id}</span>
                                    </div>
                                </div>
                                <div className="formLine">
                                    <label id="lblFname" htmlFor="lblFname">
                                        First name
                                    </label>
                                    <input name="txtFname" type="text" value={firstName} id="txtFname" className={disableControlsClass + " admin-label"} style={{ width: "306px" }} onChange={(e) => { setFirstName(e.target.value) }} maxLength="255" />
                                    <span id="fnameRedCross" className="red-cross"></span>
                                    <div>
                                        <span id="validatorFirstName" className="search-error" style={{ display: "none" }}>Please enter a valid First Name.</span>
                                    </div>
                                </div>
                                <div className="formLine">
                                    <label id="lblLname" htmlFor="lblLname">
                                        Last name
                                    </label>
                                    <input name="txtLname" type="text" value={lastName} id="txtLname" className={disableControlsClass + " admin-label"} style={{ width: "306px" }} onChange={(e) => { setLastName(e.target.value) }} maxLength="255" />
                                    <span id="lnameRedCross" className="red-cross"></span>
                                    <div>
                                        <span id="validatorLastName" className="search-error" style={{ display: "none" }}>Please enter a valid Last Name.</span>
                                    </div>
                                </div>
                                <div className="formLine">
                                    <label id="lblCountry" htmlFor="lblCountry">
                                        Country
                                    </label>
                                    <select name="countryDropDownList" id="countryDropDownList" style={{ width: "306px" }} className={disableControlsClass} value={country} onChange={(e) => { setCountry(e.target.value) }}>
                                        <option value="">Select a country</option>
                                        {countrys}
                                    </select>
                                </div>
                                <div className="formLine">
                                    <label id="lblBFunction" htmlFor="lblBFunction">
                                        Business Function
                                    </label>
                                    <select name="bFunctionDropDownList" id="bFunctionDropDownList" style={{ width: "306px" }} className={disableControlsClass} value={businessFunction} onChange={(e) => { setBusinessFunction(e.target.value) }}>
                                        <option value="">Select a business function</option>
                                        {businessFunctions}
                                    </select>
                                </div>
                                <div className="formLine">
                                    <label id="lblCompany" htmlFor="lblCompany">
                                        Organization
                                    </label>
                                    <input name="txtFname" type="text" value={company} id="txtFname" className={disableControlsClass + " admin-label"} style={{ width: "306px" }} onChange={(e) => { setCompany(e.target.value) }} maxLength="255" />
                                    <span id="companyRedCross" className="red-cross"></span>
                                    <div>
                                        <span id="validatorCompany" className="search-error" style={{ display: "none" }}>Please enter a valid Company Name.</span>
                                    </div>
                                </div>
                                <div className="formLine">
                                    <label id="lblEmail" htmlFor="lblEmail">
                                        E-mail address
                                    </label>
                                    <input name="txtEmail" type="text" value={email} id="txtEmail" className={isEmailInInValid ? "search-error-input admin-label " + disableControlsClass : disableControlsClass + " admin-label"} style={{ width: "306px" }} onChange={(e) => { setEmail(e.target.value) }} maxLength="255" />
                                    {
                                        isEmailInInValid &&
                                        <>
                                            <span id="emailRedCross" className="red-cross"></span>
                                            <div>
                                                <span id="validatorEmail" className="search-error">Please enter a valid Email Address.</span>
                                            </div>
                                        </>
                                    }

                                </div>
                                <div className="formLine">
                                    <label id="lblEmailConfirmation" htmlFor="lblEmailConfirmation">
                                        Confirm E-mail address
                                    </label>
                                    <input name="txtEmailConfirmationValue" type="text" value={confirmEmail} id="txtEmailConfirmationValue" className={emailMismatch ? "search-error-input admin-label " + disableControlsClass : disableControlsClass + " admin-label"} style={{ width: "306px" }} onChange={(e) => { setConfirmEmail(e.target.value) }} maxLength="255" />
                                    {
                                        emailMismatch &&
                                        <>
                                            <span id="emailConfirmRedCross" className="red-cross"></span>
                                            <div>
                                                <span id="emailCompareValidator" className="search-error">E-mail address does not match.</span>
                                            </div>
                                        </>
                                    }

                                </div>
                                <div className="formLine">
                                    <label id="lblMemberSinceVallue" htmlFor="lblMemberSinceVallue">Member Since</label>
                                    <div className="unchanged-value">
                                        <span id="lblMemberSinceVallue">{memberSince}</span>
                                    </div>
                                </div>
                                <div className="formLine">
                                    <label id="lblSubscriptionDate" htmlFor="lblSubscriptionDate"> Subscription Date</label>
                                    <div className="unchanged-value">
                                        <span id="lblSubscriptionDate">{subscriptionDate}</span>
                                    </div>
                                </div>

                                <div className="formLine">
                                    <label htmlFor="lblLastModified" id="lblLastModified">
                                        Last modified
                                    </label>
                                    <div className="unchanged-value">
                                        <span id="lblLastModifiedValue">{lastModifiedDate}</span>
                                    </div>

                                </div>
                                <div className="formLine">
                                    <label id="lblNewPassword">
                                        Change password
                                    </label>
                                    <input name="txtPasswordChange" type="text" minLength="8" maxLength="82" id="txtPasswordChange" className={isPasswordInInValid ? "search-error-input admin-label " + disablePasswordResetClass : disablePasswordResetClass + " admin-label"} style={{ width: "306px" }} onChange={(e) => { setNewPassword(e.target.value) }} />
                                    {
                                        isPasswordInInValid &&
                                        <>
                                            <span id="passwordRedCross" className="red-cross"></span>
                                            <div>
                                                <span id="validatorPasswordComplexity" className="search-error">Please enter a valid password.</span><br />
                                                <span id="validatorPasswordComplexity" className="search-error">Must be at least 8 characters long and at max 82 characters and contain at least one number,</span><br />
                                                <span id="validatorPasswordComplexity" className="search-error">and should not begin or end with a space.</span>
                                            </div>
                                        </>
                                    }
                                </div>

                                <div className="formLine">
                                    <label id="lblPasswordConfirm">
                                        Confirm password
                                    </label>
                                    <input name="txtPasswordChangeConfirmation" type="text" minLength="8" maxLength="82" id="txtPasswordChangeConfirmation" className={passwordMismatch ? "search-error-input admin-label " + disablePasswordResetClass : disablePasswordResetClass + " admin-label"} style={{ width: "306px" }} onChange={(e) => { setConfirmNewPassword(e.target.value) }} />
                                    {
                                        passwordMismatch &&
                                        <>
                                            <span id="txtPasswordConfirmRedCross" className="red-cross"></span>
                                            <div>
                                                <span id="PasswordCompareValidator" className="search-error">Passwords doesn't match.</span>
                                            </div>
                                        </>
                                    }
                                </div>

                                <div className="formLine">
                                    <label id="lblTitle" htmlFor="lblTitle">
                                        Job title
                                    </label>
                                    <select name="titleDropDownList" id="titleDropDownList" style={{ width: "306px" }} className={disableControlsClass} value={jobTitle} onChange={(e) => { setJobTitle(e.target.value) }}>
                                        <option value="">Select a  job title</option>
                                        {jobTitles}
                                    </select>
                                </div>

                                <div className="formLine">
                                    <label id="lblCompanySize" htmlFor="lblCompanySize">
                                        Company size</label>
                                    <select name="ddlCompanySize" id="ddlCompanySize" tabIndex="7" style={{ width: "306px" }} className={disableControlsClass} value={companySize} onChange={(e) => {
                                        setCompanySize(e.target.value)
                                    }}>
                                        <option key="0" value="0">- Please Select -</option>
                                        {companySizes}
                                    </select>
                                </div>

                                <div className="formLine">
                                    <label id="lblDisplayName" htmlFor="lblDisplayName">
                                        Display name</label>
                                    <div className="unchanged-value">
                                        <span id="lblDisplayNameValue">{displayName}</span>
                                    </div>
                                </div>

                                <div className="formLine">
                                    <label htmlFor="display_name">
                                        Origin of account creation</label>
                                    <div className="unchanged-value">

                                        <span id="lblOriginAccountCreation">{originOfAccountCreation}</span>
                                    </div>
                                </div>

                                <div className="formLine" onChange={(e) => {
                                    setRBClient(e.target.value === "1")
                                }
                                }>
                                    <label id="Label1" htmlFor="Label1">
                                        Client (cat III User)</label>
                                    <span className={"top8pxValue"} >
                                        <input id="rbClientYes" type="radio" name="rbClient" value="1" checked={rbClient} className={disableControlsClass} />
                                        <span className="radioButtonLabel">&nbsp;Yes&nbsp;</span>
                                        <input id="rbClientNO" type="radio" name="rbClient" value="0" checked={!rbClient} className={disableControlsClass} />
                                        <span className="radioButtonLabel">&nbsp;No&nbsp;</span>
                                    </span>
                                </div>

                                {
                                    roleId !== '1' &&
                                    <div id="divBU" className={buDivClassName}>
                                        <label id="lblBU" htmlFor="lblBU"> Business Unit</label>
                                        <div id="divRoleWithBU">{businessUnits}</div>
                                    </div>
                                }
                            </div>
                        }
                    </div>
                </div>

                {roleId !== '2' &&
                    <>
                        {
                            Object.keys(subscriptions).length > 0 &&
                            <Subscriptions subscriptions={subscriptions} userSubscriptions={userSubscriptions} updateUserSubscriptions={setUserSubscriptions} userEmailPref={userEmailPref} setUserEmailPref={setUserEmailPref} globalOptOutAt={globalOptOutAt} setGlobalOptOutAt={setGlobalOptOutAt} isPageReloadRequired={isPageReloadRequired} />
                        }
                        {
                            Object.keys(navigationPreferences).length > 0 &&
                            <NavigationPreferences navigationPreferences={navigationPreferences} setNavigationPreferences={setNavigationPreferences} selectedNavPrefs={selectedNavPrefs} setSelectedNavPrefs={setSelectedNavPrefs} isPageReloadRequired={isPageReloadRequired} />
                        }
                    </>
                }


            </div>
            <div className="edit-submit-button container">
                <button id="btnDiscardChanges" className="cancel" onClick={() => {
                    history.go(0)
                }}>Discard changes</button>
                <button className="submit" onClick={handleSubmit}>Submit</button>
            </div>
        </>
    );
}