/* eslint-disable no-useless-escape */
const isValidText = (value) => /^[a-zA-Z ]+$/.test(value);

const isValidEmail = (email) => {
	const emailValidator = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return emailValidator.test(email);
};

const isEmpty = (value) => !value.trim().length;

const hasSpaces = (value) => value.trim().includes(" ");


const isNotEmptyValidText = (value) => /^[a-zA-Z]+( [a-zA-Z]+)*$/.test(value);

const isValidURL = (url) => {
    try {
        new URL(url);
        return true;
    } catch (_) {
        return false;
    }
}

export { isValidText, isValidEmail, isEmpty, isNotEmptyValidText, isValidURL,hasSpaces };
