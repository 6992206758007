export const UNAUTHORIZED_ERROR_MESSAGE="You are not Authorised to access this tool. Please reach out to your admin for getting access.";
export const USER_NOT_FOUND_ERROR_MESSAGE="User does not exist. Please contact your member admin to get registered.";
export const FORBIDDEN_ERROR_MESSAGE="You have entered invalid credentials. Please enter correct credentials and try again.";
export const INVALID_EMAIL_ERROR_MESSAGE="Please enter a valid e-mail address.";
export const INVALID_UNIQUEID_ERROR_MESSAGE="Only numeric values are allowed for Unique Id.";
export const NO_CRITERIA_SELECTED_ERROR_MESSAGE="Please enter at least one search term.";
export const DEFAULT_ERROR_MESSAGE="Something went wrong. Please try again in some time.";
export const INCORRECT_INPUT_ERROR_MESSAGE="Invalid search criteria. Please correct the input values and try again.";
export const DUPLICATE_USER_EMAIL="User with this email already exists.";
export const INVALID_FILE_EXTENSION="Invalid extension. Please select valid excel file.";
export const MAX_RECORDS_ERROR="Cannot enter more than 350 records in the excel file for bulk upload.";
export const UNABLE_TO_CREATE_USER="Unable to create user. Please try again in some time.";
export const UNABLE_TO_UNSUBSCRIBE_USERS="Unable to unsubscribe users. Please try again in some time.";
export const USER_CREATION_NOT_ALLOWED="Logged in user is not tagged to any BU and cannot create a user.";


export default {
    DASHBOARD_TITLE: 'DASHBOARD',
	BUSINESS_UNIT_TAB_TITLE: 'Business Unit',
	ADMINS_TAB_TITLE: 'Admins',
	MANAGE_BUSINESS_UNIT_TITLE: 'Manage Business Unit',
	MANAGE_BUSINESS_UNIT_ADMINS_TITLE: 'Manage Business Unit Admins',
	CREATE_NEW_BUTTON_LABEL: 'Create new',
	DELETE_CONFIRMATION_MESSAGE:
		'Record will be deleted & cannot be recovered. Please confirm to proceed.',

	// Manage business unit
	NAME_LABEL: 'Name',
	NAME_PLACEHOLDER_TEXT: 'Unique name without special characters',
	DISPLAY_NAME_LABEL: 'Display Name',
	DISPLAY_NAME_PLACEHOLDER_TEXT: 'Used while sorting the list',
	CUSTOM_ROLE_LABEL: 'Custom Role',
	CUSTOM_ROLE_PLACEHOLDER_TEXT: 'To be assigned to the users',
	ACTION_LABEL: 'Action',
	ADD_NEW_ROLE_LABEL: 'Add',
	EMAIL_SUBJECT_LABEL: 'Email Subject',
	EMAIL_BODY_LABEL: 'Email Body',
	EMAIL_URL_LABEL: 'Unit Landing page',
	EMAIL_FROM_LABEL: 'Email From',

	// Buttons
	CREATE_BUTTON_LABEL: 'Create',
	CANCEL_BUTTON_LABEL: 'Cancel',

	// Manage admins
	FULL_NAME_LABEL: 'Full Name',
	USERNAME_LABEL: 'User Name',
	EMAIL_ADDRESS_LABEL: 'Email Address',
	EMAIL_ADDRESS_PLACEHOLDER_TEXT: "Please use McKinsey's email id",
	TAGGED_BUSINESS_UNIT_LABEL: "Tagged Business Unit's",
	SELECT_BUSINESS_UNIT_LABEL: 'Please select',

	// Create User
	FIRST_NAME_LABEL: 'First Name',
	FIRST_NAME_PLACEHOLDER_TEXT:
		'Please enter first name without special characters',
	LAST_NAME_LABEL: 'Last Name',
	LAST_NAME_PLACEHOLDER_TEXT:
		'Please enter last name without special characters',
	COMPANY_NAME_LABEL: 'Organization',
	COMPANY_NAME_PLACEHOLDER_TEXT: 'Please enter the company name',
	TITLE_LABEL: 'Job Title',
	TITLE_PLACEHOLDER_TEXT: "Please select user's job title",
	COUNTRY_LABEL: 'Country',
	BFUNCTION_LABEL: 'Business Function',
	USER_EMAIL_ADDRESS_PLACEHOLDER_TEXT: "Please enter user's email ID",

	// Error Messages
	NAME_REQUIRED_ERROR: 'Name is required!',
	NAME_VALID_ERROR: 'Please enter a valid name, avoid special characters and spaces.',
	DISPLAY_NAME_REQUIRED_ERROR: 'Display name is required!',
	ROLE_REQUIRED_ERROR: 'Role is required!',
	ROLE_VALID_ERROR: 'Please enter a valid role, avoid special characters and spaces.',
	ROLE_EXISTS_ERROR: 'Role already exist',
	INPUT_REQUIRED_ERROR: 'Input is required!',
	FULL_NAME_REQUIRED_ERROR: 'Please enter full name',
	USER_NAME_REQUIRED_ERROR: "Please enter admin's ADS Username",
	EMAIL_REQUIRED_ERROR: 'Email address is required',
	API_ERROR: 'Something went wrong! Please try again.',
	FIRST_NAME_REQUIRED_ERROR: 'First name is required!',
	LAST_NAME_REQUIRED_ERROR: 'Last name is required!',
	COMPANY_REQUIRED_ERROR: 'Organization name is required!',
	TITLE_REQUIRED_ERROR: 'Job Title is required!',
	COUNTRY_REQUIRED_ERROR: 'Country is required!',
	BFUNCTION_REQUIRED_ERROR: 'Business Function is required!',
	BU_REQUIRED_ERROR: 'Buisness Unit required',
	EMAIL_SUBJECT_REQUIRED_ERROR: 'Email Subject is required',
	EMAIL_BODY_REQUIRED_ERROR: 'Email Body is required',
	EMAIL_URL_REQUIRED_ERROR: 'Url is required',
	EMAIL_URL_INVALID_ERROR: 'Please enter valid url',
	EMAIL_ADDRESS_INVALID_ERROR: 'Please enter a valid email address',
};

export const jobTitles = [
	{
		label: "Please select user's job title",
		value: ''
	},
	{
		label: 'Associate / Analyst',
		value: '1'
	},
	{
		label: 'CEO',
		value: '2'
	},
	{
		label: 'Consultant / Advisor',
		value: '3'
	},
	{
		label: 'General Manager / Director',
		value: '4'
	},
	{
		label: 'Editor / Reporter/ Writer',
		value: '5'
	},
	{
		label: 'Educator / Professor / Teacher',
		value: '6'
	},
	{
		label: 'Manager / Supervisor',
		value: '7'
	},
	{
		label: 'Other',
		value: '8'
	},
	{
		label: 'C-level (Other)',
		value: '9'
	},
	{
		label: 'Partner / Owner / Entrepreneur',
		value: '10'
	},
	{
		label: 'Student',
		value: '11'
	},
	{
		label: 'Vice President / SVP / EVP',
		value: '12'
	},
	{
		label: 'President',
		value: '13'
	},
	{
		label: 'Chief Financial Officer',
		value: '14'
	},
	{
		label: 'Chief Marketing Officer',
		value: '15'
	},
	{
		label: 'Chief Operating Officer',
		value: '16'
	},
	{
		label: 'Chief Technology Officer',
		value: '17'
	},
	{
		label: 'Chief Digital Officer',
		value: '18'
	},
	{
		label: 'Chairman / Board Member',
		value: '19'
	},
	{
		label: 'Board Director',
		value: '20'
	},
	{
		label: 'Government Official / Regulator',
		value: '21'
	}
];
