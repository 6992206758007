// Only to be used to call APIs from components that do not need to be secured by token access.
export function Get(apiEndPoint, queryParams, headers) {
    
    headers=AddCommonHeadersToRequest(headers);

    var requestInfo=queryParams?apiEndPoint + queryParams:apiEndPoint;
    return fetch(requestInfo,{
        headers:headers,
        credentials: 'include',
    })
    .then(response => {
        UpdateTokensFromHeader(response);
        if (response.ok) {
            return response.json();
        }              
        else{
            return Promise.reject(response.status);
        }
    }).then(data=>{
        if(data.errorCode=='1001'){            
            localStorage.clear(); 
            alert("Session Expired! Please Login Again.");                        
            window.location.href='/signin';            
        }
        else{
            return Promise.resolve(data);
        }
    })
}

// Only to be used to call APIs from components that do not need to be secured by token access.
export function Post(apiEndPoint,requestBody,headers){    
    
    headers=AddCommonHeadersToRequest(headers);    
    return fetch(apiEndPoint, {
        method: 'POST',
        credentials: 'include',           
        body: JSON.stringify(requestBody),            
        headers: headers,        
    })
    .then(response => {
        UpdateTokensFromHeader(response);
        if (response.ok) {
            return response.json();
        }        
        else{
            return Promise.reject(response);            
        }
    }).then(data=>{
        if(data.errorCode=='1001'){             
            localStorage.clear();
            alert("Session Expired! Please Login Again."); 
            window.location.href='/signin'        
        }
        else{
            return Promise.resolve(data);
        }
    })
}

function AddCommonHeadersToRequest(headers){
    var ARRAffinity=localStorage.getItem("ARRAffinity");
    var accessToken=localStorage.getItem("token");
    var refreshToken=localStorage.getItem("refreshToken");

    if(ARRAffinity){
        headers.ARRAffinity=ARRAffinity;
    }
    if(accessToken){
        headers.accessToken=accessToken;
    }
    if(refreshToken){
        headers.refreshToken=refreshToken;
    }

    return headers;
}

function UpdateTokensFromHeader(response){
    if(response.headers.get('access_token')){
        let accessToken= response.headers.get('access_token');
        localStorage.setItem("token",accessToken);
    }
    if(response.headers.get('refresh_token')){
        let refreshToken= response.headers.get('refresh_token');

        localStorage.setItem("refreshToken",refreshToken);
    }
    if(response.headers.get('ARRAffinity')){
        localStorage.setItem("ARRAffinity",response.headers.get('ARRAffinity'));
    }
}