import React, { useState, useEffect } from 'react';
import "../../css/EditUser.css";

export default function NavigationPreferences(props) {

    const [navigationPreferences, setNavigationPreferences] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [isActive, setIsActive] = useState(false);

    useEffect(() => {
        let selectedNavPrefs = props.selectedNavPrefs;
        setNavigationPreferences(props.navigationPreferences.map(item => {
            return (
                <div>
                    <h3>{item.name}</h3>
                    <ul className="list halfWidthContent">
                        {
                            item?.items?.map(navItem => {
                                let selected = navItem.subscribed;
                                let indexOfCurrentItem = selectedNavPrefs.indexOf(navItem.id);
                                if (selected && indexOfCurrentItem === -1) {
                                    selectedNavPrefs.push(navItem.id)
                                }
                                else if (!selected && indexOfCurrentItem !== -1) {
                                    selectedNavPrefs.splice(indexOfCurrentItem, 1);
                                }
                                const handleChange = (e, navItemId) => {
                                    selected = !selected
                                    let updatedNavPrefs = props.navigationPreferences.map(updateItem => {
                                        let updated = updateItem.items.map(updateSubItem => {
                                            if (updateSubItem.id === navItemId) {
                                                return { ...updateSubItem, subscribed: selected }
                                            }
                                            return { ...updateSubItem }
                                        });
                                        return { ...updateItem, items: updated }
                                    });

                                    props.setNavigationPreferences([...updatedNavPrefs]);
                                }
                                return (
                                    <li>
                                        <span alt={navItem.id}>
                                            <input type="checkbox" value={navItem.id} checked={navItem.subscribed} onChange={(e) => { handleChange(e, navItem.id) }} />
                                            <label>{navItem.name}</label>
                                        </span>
                                    </li>
                                )
                            })
                        }
                    </ul>
                </div>
            )
        }))
        props.setSelectedNavPrefs([...selectedNavPrefs])
    }, [props.navigationPreferences, props.isPageReloadRequired]);

    const handleSelectAllChange = (e) => {
        let selectAllCB = !selectAll;
        setSelectAll(selectAllCB);
        let updatedNavPrefs = props.navigationPreferences.map(updateItem => {
            let updated = updateItem.items.map(updateSubItem => {
                return { ...updateSubItem, subscribed: selectAllCB }
            });
            return { ...updateItem, items: updated }
        });
        props.setNavigationPreferences([...updatedNavPrefs]);
    }
    return (
        <div id="NavigationPanel">
            <div className="accordion">
                <div className="accordion-item">
                    <div className="accordion-title" onClick={() => setIsActive(!isActive)}>
                        <div><h4>Navigation Preferences</h4></div>
                        <div className="accordion-expand-symbol">{isActive ? '-' : '+'}</div>
                    </div>
                    {
                        isActive &&
                        <div className="panel accordion-content">
                            <ul class="list halfWidthContent select-all">
                                <li>
                                    <input type="checkbox" checked={selectAll} onChange={(e) => { handleSelectAllChange(e) }} /> <label>Select All / Deselect All</label>
                                </li>
                            </ul>
                            <div>
                                {navigationPreferences}
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}