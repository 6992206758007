import jwt from "jwt-decode";

export function ValidateToken(token) {
  let user = jwt(token);
  if (Date.now() >= user?.exp * 1000) {
    return 1001;
  }
  return validateRoles(user);
}

export function validateRoles(user) {
  const memberAdminRoles = process.env.REACT_APP_MEMBER_ADMIN_ROLES.trim().split(',');
//   const parsedRoles = Object.keys(JSON.parse(memberAdminRoles));

  const groupClaims = user?.["groups"];

  const rolesInGroupClaims = memberAdminRoles
    .filter((role) => groupClaims.includes(role));

  const siteCoreRoles = rolesInGroupClaims.filter((role) =>
    role.includes("ext-member")
  );
  const formSiteCoreRoles = rolesInGroupClaims.find((role) =>
    role.includes("form-admin")
  );

  if (
    (!siteCoreRoles || siteCoreRoles.length === 0) &&
    (!formSiteCoreRoles || formSiteCoreRoles.length === 0)
  ) {
    return { adminRole: 0, formAdminRole: 0 };
  }

  let roleName =  siteCoreRoles.find((role) => role.includes("super")) ||
    siteCoreRoles.find((role) => role.includes("practice")) ||
    siteCoreRoles[0];

  return getRoleId(roleName ?? '', formSiteCoreRoles);
}

function getRoleId(roleName, formExportRoleName) {
 
  let roleIdObject = { adminRole: 0, formAdminRole: 0 };
  
  if(roleName.includes("super-admin")){
    roleIdObject.adminRole = 3;    
  }
  else if (roleName.includes("practice-admin")) {
    roleIdObject.adminRole = 2;
  }
  else if(roleName.includes("admin")){
    roleIdObject.adminRole = 1;
  }
  roleIdObject.formAdminRole =
    formExportRoleName && formExportRoleName.length > 0 ? 1 : 0;
  return roleIdObject;
}
