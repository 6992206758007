import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { TreeSelect, Space, Divider } from 'antd';
import Button from '../../Common/Button/Button';
import InputField from '../../Common/Input/Input';
import LoadingSpinner from '../../Common/LoadingSpinner';
import { apiConfig } from '../../../Utility/apiConfig';
import { isValidEmail, isEmpty } from '../../../Utility/validation';
import { sanitizeInput } from '../../../Utility/helper';
import Strings from '../../../Common/Constants';
import './style.scss';

const { SHOW_PARENT } = TreeSelect;

const ManageAdmin = ({ adminData, type, onCancel, updateTabList,Get,Post }) => {
	const manageAdminRef = useRef(null);

	const [formData, setFormData] = useState({
		Name: adminData.name,
		Username: adminData.username,
		Email: adminData.email,
		BusinessUnits: [],
		taggedBusinessUnit: undefined,
		formErrorMessage: '',
		fullNameErrorMesage: '',
		userNameErrorMessage: '',
		emailAddressErrorMessage: '',
		initialUserName: adminData.username,
		isDisabled: true
	});

	const isEditing = type === 'edit';

	const {
		Name,
		Username,
		Email,
		BusinessUnits,
		taggedBusinessUnit,
		formErrorMessage,
		fullNameErrorMesage,
		userNameErrorMessage,
		emailAddressErrorMessage,
		initialUserName,
		isDisabled
	} = formData;

	// for business units dropdown
	const [isLoading, setLoader] = useState(true);

	useEffect(() => {
		const taggedBU = adminData.userBusinessUnits
			? adminData.userBusinessUnits.map(
					(userBusinessUnit) => userBusinessUnit.name
			  )
			: [];

		Get(apiConfig.CellTab.listApi,new URLSearchParams(),{}).then(
			(response) => {
				const businessUnits = response
					.map((businessUnit) => ({
						Id: businessUnit.id,
						value: businessUnit.name,
						title: businessUnit.name,
						isRoleAvailable: businessUnit.rolesInBusinessUnit.length
					}))
					.filter((businessUnit) => businessUnit.isRoleAvailable);

				setFormData((prevFormData) => ({
					...prevFormData,
					taggedBusinessUnit: taggedBU,
					BusinessUnits: businessUnits,
					isDisabled: false
				}));

				setLoader(false);
			},
			() => {
				setFormData((prevFormData) => ({
					...prevFormData,
					isDisabled: false
				}));

				setLoader(false);
			}
		);
	}, []);

	const handleInputChange = (event) => {
		const inputElement = event.target;
		let { value } = inputElement;
		const key = inputElement.getAttribute('datakey');
		if (key === 'Username') value = value.substring(4);
		setFormData({ ...formData, [key]: value });
	};

	const handleDropDownChange = (value) => {
		setFormData({ ...formData, taggedBusinessUnit: value });
	};

	const handleUserNameValidation = () => {
		const userNameToValidate = sanitizeInput(Username);

		// check if userName is empty then throw required field error, otherwise validate if it's an existing username.
		if (isEmpty(userNameToValidate)) {
			setFormData({
				...formData,
				userNameErrorMessage: Strings.USER_NAME_REQUIRED_ERROR
			});
		} else {
			// check if it's an edit window
			if (isEditing && Username === initialUserName) {
				setFormData({
					...formData,
					userNameErrorMessage: ''
				});

				return;
			}

			setFormData({ ...formData, isDisabled: true });

			Get(
				`${apiConfig.AdminTab.checkUserName}/${Username}`,new URLSearchParams(),{}
			).then(
				(response) => {
					setFormData((prevFormData) => ({
						...prevFormData,
						userNameErrorMessage: '',
						isDisabled: false
					}));
				})
				.catch((errorResponse) => {
					
					if(errorResponse===404){
						setFormData((prevFormData) => ({
							...prevFormData,
							isDisabled: false,
							userNameErrorMessage: 'Business unit admin already exists.'
						}));
					}					
				});
		}
	};

	const handleFormSubmitValidation = () => {
		let isFullNameValid = true;
		let isUserNameValid = true;
		let isEmailAddressValid = true;
		let userNameValMsg = '';

		if (isEmpty(Name)) {
			isFullNameValid = false;
		}

		// Check if User name field has an error message already otherwise check if it is empty.
		if (userNameErrorMessage || isEmpty(sanitizeInput(Username))) {
			isUserNameValid = false;
			userNameValMsg =
				userNameErrorMessage || Strings.USER_NAME_REQUIRED_ERROR;
		}

		if (!(Email && isValidEmail(Email))) {
			isEmailAddressValid = false;
		}

		setFormData({
			...formData,
			fullNameErrorMesage: isFullNameValid
				? ''
				: Strings.FULL_NAME_REQUIRED_ERROR,
			userNameErrorMessage: userNameValMsg,
			emailAddressErrorMessage: isEmailAddressValid
				? ''
				: Strings.EMAIL_REQUIRED_ERROR
		});

		return isFullNameValid && isUserNameValid && isEmailAddressValid;
	};

	const getUsername = (userName) => {
		if (userName.length >= 4 && userName.startsWith('ADS')) {
			return userName;
		}
		return `ADS\\${userName}`;
	};

	const handleFormSubmit = () => {
		if (!handleFormSubmitValidation()) {
			return;
		}

		const payload = {
			user:{
				...formData,
				Name: sanitizeInput(formData.Name),
				Username: Username.startsWith('ADS')
					? sanitizeInput(Username)
					: `ADS\\${sanitizeInput(Username)}`,
				Email: sanitizeInput(formData.Email),
				Id: adminData.id,
				CreatedBy: localStorage.getItem("email")
			}
			
		};

		payload.BusinessUnits = BusinessUnits.filter((businessUnit) => {
			let flag = false;
			const buValues = taggedBusinessUnit;

			// eslint-disable-next-line no-unused-expressions
			buValues &&
				buValues.map((businessUnitValue) => {
					flag = flag || businessUnitValue === businessUnit.value;
					return flag;
				});

			return flag;
		}).map((businessUnit) => ({ Id: businessUnit.Id }));
        
		const headers = {
            "content-type": "application/json;",
        };
		Post(apiConfig.AdminTab.createApi, payload,headers).then(
				(data) => {
					 onCancel();
					 updateTabList();
				}		
			).catch(errorResponse=>{				
					setFormData({
						...formData,
						formErrorMessage: "An error occured while trying to create/update user. Please try again or contact your system administrator."
					});				
			});
	};

	return (
		<div className="add-practice" ref={manageAdminRef}>
			<p className="error">{formErrorMessage}</p>
			<InputField
				labelText={Strings.FULL_NAME_LABEL}
				isRequired
				errMsg={fullNameErrorMesage}
				value={Name}
				dataKey="Name"
				onChange={handleInputChange}
				reqValidationMessage={Strings.FULL_NAME_REQUIRED_ERROR}
			/>
			<InputField
				labelText={Strings.USERNAME_LABEL}
				isRequired
				onChange={handleInputChange}
				onBlur={handleUserNameValidation}
				errMsg={userNameErrorMessage}
				value={getUsername(Username)}
				dataKey="Username"
			/>
			<InputField
				labelText={Strings.EMAIL_ADDRESS_LABEL}
				placeHolder={Strings.EMAIL_ADDRESS_PLACEHOLDER_TEXT}
				isRequired
				errMsg={emailAddressErrorMessage}
				value={Email}
				dataKey="Email"
				fieldType="email"
				onChange={handleInputChange}
				reqValidationMessage={Strings.EMAIL_REQUIRED_ERROR}
				fieldValidationMessage={Strings.EMAIL_ADDRESS_INVALID_ERROR}
			/>

			<div className="business-unit-select">
				<div className="input-label">
					{Strings.BUSINESS_UNIT_TAB_TITLE}
				</div>
				<TreeSelect
					placeholder={Strings.SELECT_BUSINESS_UNIT_LABEL}
					value={taggedBusinessUnit}
					className="cell-drop-down default-input-style"
					treeData={BusinessUnits}
					onChange={handleDropDownChange}
					getPopupContainer={() => manageAdminRef.current}
					treeCheckable
					showArrow
					showCheckedStrategy={SHOW_PARENT}
				/>
				{isLoading ? <LoadingSpinner /> : ''}
			</div>

			<div>
				<Divider />
				<Space size="middle">
					{/* Type changes TBR */}
					<Button
						type={isEditing ? 'update' : type}
						clickHandler={handleFormSubmit}
						isDisabled={isDisabled}
						isLoading={isDisabled}
					/>
					<Button type="cancel" clickHandler={onCancel} />
				</Space>
			</div>
		</div>
	);
};

ManageAdmin.defaultProps = {
	adminData: {
		name: '',
		username: '',
		email: '',
		BusinessUnits: [],		
	},
	type: 'Create'
};

ManageAdmin.propTypes = {
	onCancel: PropTypes.func.isRequired,
	updateTabList: PropTypes.func.isRequired,
	adminData: PropTypes.objectOf(PropTypes.any),
	type: PropTypes.string
};

export default ManageAdmin;
