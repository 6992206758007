import React from 'react';
import { Table, Tooltip } from 'antd';
import PropTypes from 'prop-types';
import TableActions from '../../Manage BU//TableActions/TableActions';
import AdminTaggedPractice from '../../Manage BU/AdminTaggedPractice/AdminTaggedPractice';
import './styles.scss';

const { Column } = Table;

const TableContainer = ({ columns, data, tabActions, handleActionClick }) => (
		<Table dataSource={data} pagination={false} className="mck-table">
		{columns.map(({ key, dataIndex, title, ellipsis, width }) => {
			switch (key) {
				case 'role':
					return (
						<Column
							title={title}
							dataIndex={dataIndex}
							key={key}
							render={(businessRoles) => (
								<span>{businessRoles[0].role}</span>
							)}
						/>
					);
				case 'from':
					return (
						<Column
							title={title}
							dataIndex={dataIndex}
							key={key}
							render={(email) =>   (
									<span>{email[0]?.fromEmail}</span>
								)
							}
						/>
					);
				case 'sub':
					return (
						<Column
							title={title}
							dataIndex={dataIndex}
							key={key}
							render={(email) =>   (
									<span>{email[0]?.subject}</span>
								)
							}
						/>
					);
				case 'body':
					return (
						<Column
							title={title}
							dataIndex={dataIndex}
							key={key}
							ellipsis={true}
							render={(email)=>(
								<Tooltip placement="topLeft" title={ email[0]?.Body }>
									<span>{ email[0]?.body }</span>
								</Tooltip>
							)}
						/>
					);
				case 'unitUrl':
					return (
						<Column
							title={title}
							dataIndex={dataIndex}
							key={key}
							width={width}
							render={(email) => (
								<a href={email[0]?.landingPageInBody} target="_blank">{email[0]?.landingPageInBody}</a>
							)}
						/>
					);
				case 'taggedPractice':
					return (
						<Column
							title={title}
							dataIndex={dataIndex}
							key={key}
							render={(taggedPracticeData) => (
								<AdminTaggedPractice
									taggedPracticeData={taggedPracticeData}
								/>
							)}
						/>
					);
				case 'action':
					return (
						<Column
							title={title}
							key={key}
							dataIndex={dataIndex}
							width={180}
							render={(dataKey) => (
								<TableActions
									actions={tabActions.actions}
									data={data[dataKey]}
									handleActionClick={handleActionClick}
								/>
							)}
						/>
					);
				default:
					return (
						<Column title={title} dataIndex={dataIndex} key={key} ellipsis={ellipsis} width={width} />
					);
			}
		})}
	</Table>
);

TableContainer.defaultProps = {
	data: null,
	tabActions: null,
	width: 50,
	ellipsis: false
};

TableContainer.propTypes = {
	columns: PropTypes.arrayOf(PropTypes.object).isRequired,
	data: PropTypes.arrayOf(PropTypes.object),
	ellipsis: PropTypes.bool,
	tabActions: PropTypes.objectOf(PropTypes.any),
	handleActionClick: PropTypes.func.isRequired,
};

export default TableContainer;
