import React from 'react';
import Dashboard from '../Manage BU/Dashboard/Dashboard';
import 'antd/dist/antd.css';
import '../../css/_global.scss';


export default function ManageBusinessUnits(props) {        
    return (
        <Dashboard {...props} />
    );
}
