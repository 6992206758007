import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Modal as Popup } from 'antd';
import Portal from '../Portal/Portal';

const Modal = ({ children, title, onCancel, defaultShow }) => (
	<Portal>
		<Popup
			title={title.toUpperCase()}
			visible={defaultShow}
			onCancel={onCancel}
			footer={null}
			maskStyle={{ backgroundColor: 'rgba(0,0,0,0.7)' }}
		>
			{children}
		</Popup>
	</Portal>
);

Modal.propTypes = {
	children: PropTypes.instanceOf(Object).isRequired,
	title: PropTypes.string.isRequired,
	defaultShow: PropTypes.bool.isRequired,
	onCancel: PropTypes.func.isRequired
};

export default memo(Modal);
