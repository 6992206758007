/* It exports all the required skeleton data(columns, actions & type of tabs) for rendering different tabs in dashboard */
import Strings from '../../../Common/Constants';

export const tabList = [
	{
		key: 'CellTab',
		tab: Strings.BUSINESS_UNIT_TAB_TITLE,
	},
	{
		key: 'AdminTab',
		tab: Strings.ADMINS_TAB_TITLE,
	},
];

/* Manage API's & related data for different tab Actions */
export const tabActions = {
	/* Keep the same key here as of tabList */
	CellTab: {
		key: 'BuTabAction',
		actions: {
			edit: {
				apiUrl: 'BusinessUnits',
			},
			delete: {
				apiUrl: 'businessunit',
			},
		},
	},
	AdminTab: {
		key: 'AdminTabAction',
		actions: {
			edit: {
				apiUrl: 'user',
			},
			delete: {
				apiUrl: 'user/MemberAdmin/deleteAdmin',
			},
		},
	},
};

/* Manage data for tab pane view */
export const tabsViewData = {
	/* Keep the same key here as of tabList */
	CellTab: {
		displayTitle: Strings.MANAGE_BUSINESS_UNIT_TITLE,
		id: 'CellTab',
		columns: [
			{
				title: Strings.NAME_LABEL,
				dataIndex: 'name',
				key: 'name',
				width: 100
			},
			{
				title: Strings.DISPLAY_NAME_LABEL,
				dataIndex: 'displayName',
				key: 'displayName',
			},
			{
				title: Strings.CUSTOM_ROLE_LABEL,
				dataIndex: 'McKBusinessUnitRoles',
				key: 'role',
			},
			{
				title: Strings.EMAIL_FROM_LABEL,
				dataIndex: 'emailInBusinessUnit',
				key: 'from',
			},
			{
				title: Strings.EMAIL_SUBJECT_LABEL,
				dataIndex: 'emailInBusinessUnit',
				key: 'sub',
			},
			{
				title: Strings.EMAIL_BODY_LABEL,
				dataIndex: 'emailInBusinessUnit',
				key: 'body',
			},
			{
				title: Strings.EMAIL_URL_LABEL,
				dataIndex: 'emailInBusinessUnit',
				key: 'unitUrl',
				width: 160
			},
			{
				title: Strings.ACTION_LABEL,
				key: 'action',
				dataIndex: 'actionKey',
			},
		],
	},

	AdminTab: {
		displayTitle: Strings.MANAGE_BUSINESS_UNIT_ADMINS_TITLE,
		id: 'AdminTab',
		columns: [
			{
				title: Strings.FULL_NAME_LABEL,
				dataIndex: 'name',
				key: 'fullName',
			},
			{
				title: Strings.USERNAME_LABEL,
				dataIndex: 'username',
				key: 'userName',
			},
			{
				title: Strings.EMAIL_ADDRESS_LABEL,
				dataIndex: 'email',
				key: 'email',
			},
			{
				title: Strings.TAGGED_BUSINESS_UNIT_LABEL,
				key: 'taggedPractice',
				dataIndex: 'userBusinessUnits',
			},
			{
				title: Strings.ACTION_LABEL,
				key: 'action',
				dataIndex: 'actionKey',
				isHiddenInModal: true,
			},
		],
	},
};
