import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

const AdminTaggedPractice = ({ taggedPracticeData }) => {
	const taggedPracticeNames = [];
	if (taggedPracticeData && taggedPracticeData.length) {
		taggedPracticeData.map((taggedPractice) => {
			taggedPracticeNames.push(taggedPractice.name);
			return '';
		});
	}

	return (
		<span className="tagged-practices">
			{taggedPracticeNames.join(', ')}
		</span>
	);
};

AdminTaggedPractice.propTypes = {
	taggedPracticeData: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default AdminTaggedPractice;
