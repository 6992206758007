import React, { useState, useEffect, useCallback } from "react";
import { useOktaAuth } from "@okta/okta-react";
import "../css/Login.css";
import { Redirect } from "react-router-dom";
import LoadingSpinner from "./Common/LoadingSpinner";
import NavMenu from "./Header/NavMenu";
import { Button, Container, Row } from "react-bootstrap";
import { Post } from "../Utility/ApiRequestExcecutor";
import { ValidateToken } from "../Utility/TokenValidator";
import * as Constants from '../Common/Constants';

export default function LoginComponent(props) {
  const [errorMessage, setErrorMessage] = React.useState("");
  const [loading, setLoading] = useState(false);
  const [adminRoleId, setadminRoleId] = useState(0);
  const [formAdminRoleId, setformAdminRoleId] = useState(0);
  const { oktaAuth, authState } = useOktaAuth();
  const [userInfo, setUserInfo] = useState(null);
  const [success, setSuccess] = useState(false);
  const [isUserAuthorised, setIsUserAuthorised] = useState(false);

  function fetchPracticeIds(email) {
    if (
      !String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    ) {
      setErrorMessage(Constants.INVALID_EMAIL_ERROR_MESSAGE);
      return;
    }
    const data = {
      Email: email,
    };
    const headers = {
      "content-type": "application/json;",
    };
    setLoading(true);

    Post("login", data, headers)
      .then((response) => {
        if (response.practiceIds) {
          //setErrorMessage("");
          localStorage.setItem("practiceIds", response.practiceIds);
        }
        setLoading(false);
        setSuccess(true);
      })
      .catch((error) => {
        error.text().then((details) => {
          let errorDetails = JSON.parse(details);

          if (errorDetails.statusCode === "404") {
            setErrorMessage(Constants.USER_NOT_FOUND_ERROR_MESSAGE);
          } else if (errorDetails.statusCode === "403") {
            setErrorMessage(Constants.FORBIDDEN_ERROR_MESSAGE);
          } else {
            setErrorMessage(Constants.DEFAULT_ERROR_MESSAGE);
          }
          setLoading(false);
        });
      });
  }
  useEffect(() => {
    if (props.location.state?.errorMessage) {
      setErrorMessage(props.location.state?.errorMessage);
      //setIsUserAuthorised(props.location.state?.isUserAuthorised);
    } else {
      setErrorMessage("");
    }
  }, [props.location.state]);

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const isUserAuthorised = props?.location?.state?.isUserAuthorised ?? true
      setIsUserAuthorised(isUserAuthorised);

        if (!authState || !authState.isAuthenticated || !isUserAuthorised) {
        // When user isn't authenticated, forget any user info
        setUserInfo(null);
        localStorage.clear();
        setLoading(false);
      }

      if (authState && authState.isAuthenticated && isUserAuthorised) {
        setUserInfo(authState.idToken.claims);

        const roleObject = ValidateToken(authState.accessToken?.accessToken);
        setadminRoleId(roleObject.adminRole);
        setformAdminRoleId(roleObject.formAdminRole);
        
        localStorage.clear();
        localStorage.setItem("roleId", JSON.stringify(roleObject.adminRole));
        localStorage.setItem(
          "formAdminRoleId",
          JSON.stringify(roleObject.formAdminRole)
        );
        localStorage.setItem("email", authState.idToken.claims.email);

        fetchPracticeIds(authState.idToken.claims.email);
      }
    }
      fetchData();

  }, [authState, oktaAuth]); // Update if authState changes

  function getRedirect() {
    if (props.location.state?.errorMessage) {
      return adminRoleId === 0 && formAdminRoleId === 1 ? "form-data" : "home";
    }
    return (
      props.location.state?.from?.pathname ||
      (adminRoleId === 0 && formAdminRoleId === 1 ? "form-data" : "home")
    );
  }

  function login() {
    setLoading(true);

    const originalUri=props?.location?.state?.from?.pathname?props.location.state.from.pathname:"/";
    oktaAuth.signInWithRedirect({originalUri: originalUri});
    setLoading(false);
  }

  return (
    <>
      <NavMenu isLoginPage={true} selectedTab={1} />

      {(!authState || !authState.isAuthenticated || !isUserAuthorised) && (
        <>
          {loading ? <LoadingSpinner /> : <p></p>}
          <Container className="border border-dark mt-5 login-container">
            <Row className="justify-content-center p-3">
              <h3 className="text-center">Welcome to Member Admin Portal</h3>
            </Row>
            {
              errorMessage && (
              <Row className="justify-content-center p-3">
                <div className="text-center error">{errorMessage}</div>
              </Row>
            )}

            <Row className="justify-content-center p-3">
              {loading ? (
                <LoadingSpinner />
              ) : (
                <>
                  <p>To continue, please sign in using Firm SSO.</p>
                  <Button variant="primary" onClick={login}>
                    Sign in with SSO
                  </Button>
                </>
              )}
            </Row>
          </Container>
        </>
      )}
      {authState && authState.isAuthenticated && success && (
        <Redirect
          to={{
            pathname: getRedirect(),
          }}
        />
      )}
    </>
  );
}
