import React from 'react';
import PropTypes from 'prop-types';
import Strings from '../../../Common/Constants';
import Button from '../Button/Button';
import TableContainer from '../Table/Table';

const TabPaneView = ({ tabData, tabActions, handleActionClick }) => (
	<>
		<h3 className="tab-pane-headline">
			{tabData.displayTitle}
			{tabData.id !== "AdminTab" &&
				<Button
				type={Strings.CREATE_NEW_BUTTON_LABEL}
				clickHandler={() => handleActionClick('Create')}
			/>
			}
			
		</h3>
		<TableContainer
			columns={tabData.columns}
			data={tabData.data}
			tabActions={tabActions}
			handleActionClick={handleActionClick}
		/>
	</>
);

TabPaneView.defaultProps = {
	tabActions: {},
};

TabPaneView.propTypes = {
	tabData: PropTypes.objectOf(PropTypes.any).isRequired,
	tabActions: PropTypes.objectOf(PropTypes.any),
	handleActionClick: PropTypes.func.isRequired,
};

export default TabPaneView;
