import React, { useState, useEffect } from "react";
import "./GetFormData.css";
import { Space } from "antd";
import Button from "../Common/Button/Button";
import * as helper from '../../Utility/helper';

export default function GetFormData(props) {
    const [isLoading, setIsLoading] = useState(false);
    const [pageId, setPageId] = useState('');
    const [langOptions, setLangOptions] = useState([]);
    const [selectedLanguageValue, setSelectedLanguageValue] = useState('en');
    const [selectedDbValue, setSelectedDbValue] = useState('Web');
    const [genericErrorMessage, setGenericErrorMessage] = useState('');
    const [options, setOptions] = useState([]);
    const optionsForDb = [
        { label: 'Web', value: 'Web' },
        { label: 'Preview', value: 'Preview' }
    ]; 


    const handlePageIdChange = (event) => {
        setGenericErrorMessage('');
        setPageId(event.target.value);
    };

    const handleOptionChange = (e) => {
        setGenericErrorMessage('');
        setSelectedDbValue(e.target.value);
    };
    const handleLanguageOptionChange = (e) => {
        setGenericErrorMessage('');
        setSelectedLanguageValue(e.target.value);
    };

    const handlecancel = () => {
        setPageId('');
        setGenericErrorMessage('');
        setSelectedLanguageValue('en');
        setLangOptions([]);
        getLangugeDropdown();
    };

    useEffect(() => {
        getLangugeDropdown();
        setOptions(optionsForDb);
    }, []);

    const getLangugeDropdown = () => {
        let buDetailseSearchParam = new URLSearchParams();
        let headers = {};
        props.Get('/formdata/getlanguagelist', buDetailseSearchParam, headers).then((data) => {
            setLangOptions(data);
            let optionsObj = data || [];
            if (optionsObj.length > 0) {
                let optArray = optionsObj.map((item) => {
                    return { label: item['languageName'], value: item['languageValue'] };
                });
                optArray.unshift({
                    label: 'English',
                    value: 'en'
                });
                let uniqueItems = optArray.filter((item, index, self) =>
                    index === self.findIndex((t) => (
                        t.value === item.value
                    ))
                );
                setLangOptions(uniqueItems);
            }
        })
    };

    const handleFormSubmit = () => {
        let today = new Date();
        setIsLoading(true);
        if (pageId !== '') {
            let formDataDownloadParam = new URLSearchParams();
            formDataDownloadParam.append("PageId", pageId);
            formDataDownloadParam.append("DatabaseName", selectedDbValue);
            formDataDownloadParam.append("Language", selectedLanguageValue);
            let headers = {};
            props.Get('/formdata?', formDataDownloadParam, headers).then((data) => {
                let responseObj = data || [];
                if (responseObj.length > 0) {
                    let fileName = `ExportData_Id_${pageId}_${today.getDate() + '-' + (today.getMonth() + 1) + '-' + today.getFullYear()}`;
                    helper.ExportDataToExcel(responseObj, fileName);
                }
                else {
                    setGenericErrorMessage("No record found for this page id " + pageId);
                }
                setIsLoading(false);
            }).catch((error) => {
                setIsLoading(false);
                setGenericErrorMessage("Please Enter proper Page Id");
            })
        }
        else {
            setIsLoading(false);
            setGenericErrorMessage("Please Enter proper Page Id; it cannot be blank");
        }
    };

    return (
        <div className="form-container container">
            <table width="100%" aria-describedby="Table with description">
                <thead>
                    <tr>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td><strong>About the tool</strong>
                            <p>Please use this tool to export custom forms data based on Page Id using the following steps</p>
                            <ol>
                                <li>Provide the Page Id with Curly Braces e.g. "367E4041-0J8A-4A6D-99D7-7D83F5A73EA7"</li>
                                <li>Select the Language</li>
                                <li>Select the Database</li>
                                <li>Click on the "Export Data" button</li>
                            </ol>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div id="wrapperContainer">
                <table className="mainTable" aria-describedby="Table with selection criteria for custom forms data to export">
                    <thead>
                        <tr>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Page Id</td>
                            <td>
                                <input className="pageInput" type="text" value={pageId} onChange={handlePageIdChange} />
                            </td>
                        </tr>
                        <tr>
                            <td>Database</td>
                            <td>
                                <select className="selectOption" onChange={handleOptionChange}>
                                    {options.map((option) => (
                                        <option key={option.value} value={option.value}>{option.label}</option>
                                    ))}
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <td>Language</td>
                            <td>
                                <select className="selectOption" onChange={handleLanguageOptionChange}>
                                    {langOptions.map((option, index) => (
                                        <option key={index} value={option.value}>{option.label}</option>
                                    ))}
                                </select>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <Space size="middle">
                    <Button
                        type="Submit"
                        clickHandler={handleFormSubmit}
                        isLoading={isLoading}
                    />
                    <Button
                        type="cancel"
                        clickHandler={handlecancel}
                        isLoading={isLoading}
                    />
                </Space>
                {
                    genericErrorMessage &&
                    <p className="error-text">{genericErrorMessage}</p>
                }
            </div>
        </div>
    );
}