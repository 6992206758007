/* eslint-disable react/prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Input } from 'antd';
import { isEmpty, isValidURL, isValidEmail } from '../../../Utility/validation';
import './styles.scss';

const { TextArea } = Input;

const InputField = (props) => {
	const {
		placeHolder,
		value,
		dataKey,
		type,
		onChange,
		className,
		isRequired,
		isLabelRequired,
		labelText,
		errMsg,
		suffix,
		disabled,
		multiline,
		rows
	} = props;

	const mandatory = isRequired ? <span className="mandtory"> *</span> : '';
	const [error, setError] = useState(null);

	const onBlur = e => {
		let { value } = e.target;
		const {
			isRequired,
			fieldType,
			defaultReqMessage,
			reqValidationMessage,
			errMsg,
			defaultValidationMessage,
			fieldValidationMessage
		} = props;
		if(isRequired){
			if(isRequired && isEmpty(value)){
				setError(reqValidationMessage || defaultReqMessage);
			} else if(
				(fieldType === 'url' && !isValidURL(value)) ||
				(fieldType === 'email' && !isValidEmail(value))
			) {
				setError(fieldValidationMessage || defaultValidationMessage);
			} else {
				setError(null);
			}
			
		}
		props.onBlur && props.onBlur(e);
	}

	useEffect(() => {
		setError(errMsg)
	},[errMsg])

	return (
		<>
			{isLabelRequired && (
				<div className="input-label">
					{labelText}
					{mandatory}
				</div>
			)}
			{multiline ?
				<TextArea
					placeholder={placeHolder}
					rows={rows}
					onChange={onChange}
					onBlur={onBlur}
					value={value}
					datakey={dataKey}
				/> : 
				<>
					<Input
						placeholder={placeHolder}
						type={type}
						onChange={onChange}
						onBlur={onBlur}
						value={value}
						datakey={dataKey}
						prefixCls={`ant-input default-input-style ${className}`}
						disabled={disabled}
					/>
					{suffix}
				</>
			}
			{error !== '' && (
				<div className="error">{error}</div>
			)}
		</>
	);
};

InputField.defaultProps = {
	placeHolder: '',
	type: 'text',
	className: '',
	suffix: '',
	labelText: 'Name',
	isRequired: false,
	isLabelRequired: true,
	errMsg: '',
	disabled: false,
	error: '',
	defaultReqMessage: 'This field is required',
	defaultValidationMessage: 'Invalid Type',
	rows: '1',
};

InputField.propTypes = {
	placeHolder: PropTypes.string,
	type: PropTypes.string,
	className: PropTypes.string,
	suffix: PropTypes.any,
	errMsg: PropTypes.string,
	labelText: PropTypes.string,
	isRequired: PropTypes.bool,
	isLabelRequired: PropTypes.bool,
	onChange: PropTypes.func.isRequired,
	onBlur: PropTypes.func,
	value: PropTypes.string.isRequired,
	reqValidationMessage: PropTypes.string,
	defaultValidationMessage: PropTypes.string,
};

export default InputField;
