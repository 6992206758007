import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Space } from 'antd';
import Button from '../../Common/Button/Button';
import { deleteDataModifier } from '../../../Utility/DataModifier';
import Strings from '../../../Common/Constants';
import { tabActions } from '../Dashboard/tabState';

const DeletePortal = ({ data, activeTab, closePortal, updateTabList,Post }) => {
	const [isLoading, setLoading] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');

	const handleDelete = () => {
		setLoading(true);
		const payload = deleteDataModifier(
			activeTab,
			tabActions[activeTab].actions.delete.apiUrl,
			data
		);

		const headers = {
            "content-type": "application/json;",
			"updatedby":localStorage.getItem("email")
        };
		// Call Delete API with payload
		Post(payload.apiUrl, payload.data,headers).then(() => {
			setLoading(false);
			closePortal();
			updateTabList();
		}).catch(error=>{
			error.text().then(details=>{
				var errorDetails=JSON.parse(details);				
				setLoading(false);
				setErrorMessage(errorDetails.message);	
			});		
		});
	};

	return (
		<div>					
			{!errorMessage &&
			<>
			<p>{Strings.DELETE_CONFIRMATION_MESSAGE}</p>
			<Space size="middle">
				<Button
					type="delete"
					isLoading={isLoading}
					clickHandler={handleDelete}
				/>
				<Button type="cancel" clickHandler={closePortal} />
			</Space>
			</>
			}
			{errorMessage && 
			<>
			<p className="error">{errorMessage}</p>
			<Button type="ok" clickHandler={closePortal}/>
			</>}
		</div>
	);
};

DeletePortal.propTypes = {
	activeTab: PropTypes.string.isRequired,
	data: PropTypes.objectOf(PropTypes.any).isRequired,
	closePortal: PropTypes.func.isRequired,
	updateTabList: PropTypes.func.isRequired,
};

export default DeletePortal;
