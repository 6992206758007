import React, { useState, useRef, useEffect } from 'react';
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import "../../css/Home.css";
import FetchData from './FetchData';
import LoadingSpinner from '../Common/LoadingSpinner';
import { CSVLink } from 'react-csv';
import * as Constants from '../../Common/Constants';
import { Link } from "react-router-dom";


export default function Home(props) {

    const [firstName, setFirstName] = React.useState("");
    const [lastName, setLastName] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [uniqueId, setUniqueId] = React.useState("");
    const [displayUserDetailsGrid, setDisplayUserDetailsGrid] = React.useState(false);
    const [loading, setLoading] = useState(false);
    const [userData, setUserData] = useState([]);
    const [displayErrorMessage, setDisplayErrorMessage] = useState(false);
    const [roleId] = useState(localStorage.getItem("roleId"));
    const [pageSize] = useState(50);
    const [pageNumber, setPageNumber] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [dataToDownload, setDataToDownload] = useState([]);
    const [userReportFileName, setUserReportFileName] = useState("UserReport.csv")
    const [errorMessage, setErrorMessage] = React.useState(Constants.NO_CRITERIA_SELECTED_ERROR_MESSAGE);
    const [invalidEmailClassName, setInvalidEmailClassName] = React.useState("");
    const [invalidUIDClassName, setInvalidUIDClassName] = React.useState("");


    const routeChange = (path) => {
        if (path) {
            props.history.push(path);
        }
    }

    useEffect(() => {
        let query = props.location.search;
        if (query) {
            let uniqueId = query.split("=")[1];
            setUniqueId(uniqueId);
            let urlSearchParams = new URLSearchParams();
            urlSearchParams.append("uniqueId", uniqueId);

            fetchData(0, false, urlSearchParams);
        }
    }, [props.location.search]);
    const csvLink = useRef()
    const columns = [
        {
            Header: <p className="sortableHeader">First Name</p>,
            accessor: 'firstName',
        },
        {
            Header: <p className="sortableHeader">Last Name</p>,
            accessor: 'lastName',
        },
        {
            Header: <p>Title</p>,
            accessor: 'titleName',
            disableSortBy: true,
        },
        {
            Header: <p className="sortableHeader">Email</p>,
            accessor: 'email',

        },
        {
            Header: <p className="sortableHeader">Registration Date</p>,
            accessor: 'registeredAt',
        },
        {
            Header: <p>Subscription Date</p>,
            accessor: 'subscribedAt',
            disableSortBy: true,
        },
        {
            Header: <p>Registered?</p>,
            accessor: 'registered',
            disableSortBy: true,
        }
    ];

    function handleSubmit(event) {
        event.preventDefault();
        resetState()
        if (email && !String(email).toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
            handleValidationError(Constants.INVALID_EMAIL_ERROR_MESSAGE, "email")
            return;
        }
        if (uniqueId && /\D/.test(uniqueId)) {
            handleValidationError(Constants.INVALID_UNIQUEID_ERROR_MESSAGE, "uniqueId")
            return;
        }
        fetchData(pageNumber, false);
    }
    const fetchData = (index, allusers, urlSearchParams) => {
        let headers = {
            recordsPerPage: pageSize,
            pageIndex: index + 1
        };
        setPageNumber(index);
        let searchCriteriaNotSelected = true;
        if (!urlSearchParams) {
            urlSearchParams = getSearchParams();
        }

        if (Array.from(urlSearchParams).length > 0) {
            searchCriteriaNotSelected = false;
        }
        if (!searchCriteriaNotSelected) {
            setDisplayUserDetailsGrid(false);
            setLoading(true);
            //Get All users to export data to CSV.
            if (allusers) {
                GetDetailsForAllUsers(urlSearchParams, headers);
            }
            else {
                GetSingleUserDetails(urlSearchParams, headers);
            }
        }
        else {
            handleValidationError(Constants.NO_CRITERIA_SELECTED_ERROR_MESSAGE, "");
        }
    }

    function GetDetailsForAllUsers(urlSearchParams, headers) {
        props.Get('user/GetAllUsers?', urlSearchParams, headers).then(data => {
            setUserReportFileName("UserReport" + getDate() + ".csv");
            setDataToDownload(data.users.map(user => {
                return ({
                    "Unique ID": user.id,
                    "First Name": user.firstName,
                    "Last Name": user.lastName,
                    Title: user.titleName,
                    Email: user.email,
                    CreateDate: user.registeredAt
                })
            }));
            setLoading(false);
            setDisplayUserDetailsGrid(true);
            csvLink.current.link.click();
        }).catch(error => {
            handleAPIException(error);
        });
    }

    function GetSingleUserDetails(urlSearchParams, headers) {
        props.Get('user?', urlSearchParams, headers)
            .then(data => {
                setTotalPages(data?.paging?.totalPages)
                setUserData(data?.users?.map(user => {
                    let link = "/details/" + user.id;
                    return ({
                        firstName: <Link to={link}>{user.firstName}</Link>,
                        lastName: <Link to={link}>{user.lastName}</Link>,
                        titleName: user.titleName,
                        email: <Link to={link}>{user.email}</Link>,
                        registeredAt: user.registeredAt,
                        subscribedAt: user.subscribedAt,
                        registered: "" + user.registered
                    })
                }
                ));
                setLoading(false);
                setDisplayUserDetailsGrid(true);
            })
            .catch(error => {
                handleAPIException(error);
            });
    }

    function handleAPIException(error) {
        if (error === 400) {
            setErrorMessage(Constants.INCORRECT_INPUT_ERROR_MESSAGE);
        }
        else {
            setErrorMessage(Constants.DEFAULT_ERROR_MESSAGE);
        }
        setDisplayErrorMessage(true);
        setLoading(false);
        setDisplayUserDetailsGrid(false);
    }

    function resetState() {
        setInvalidEmailClassName("");
        setInvalidUIDClassName("");
        setErrorMessage("");
        setDisplayErrorMessage(false);
    }

    function handleValidationError(errorMessage, errorFieldName) {
        setDisplayErrorMessage(true);
        setDisplayUserDetailsGrid(false);
        setLoading(false);
        setErrorMessage(errorMessage);
        switch (errorFieldName) {
            case "email":
                setInvalidEmailClassName("invalid");
                break;
            case "uniqueId":
                setInvalidUIDClassName("invalid");
                break;
            default:
                break;
        }
    }
    function getDate() {
        let today = new Date();
        return today.getDate() + "_" + parseInt(today.getMonth() + 1) + "_" + today.getFullYear();
    }
    function getSearchParams() {
        let urlSearchParams = new URLSearchParams();
        if (email) {
            urlSearchParams.append("email", email);
        }
        if (uniqueId) {
            urlSearchParams.append("uniqueId", uniqueId);
        }
        if (firstName) {
            urlSearchParams.append("firstName", firstName);
        }
        if (lastName) {
            urlSearchParams.append("lastName", lastName);
        }

        return urlSearchParams;
    }

    return (
        <>
            {
                loading ? (<LoadingSpinner />) : (<p></p>)
            }
            <div className={loading ? "is-disabled container" : "container"}>
                <div className="containerDiv">
                    {
                        (roleId === '3' || roleId === '2') &&
                        <Button type="button" id="lnkCreateNewUser" className="bulk-unsubscribe-btn" onClick={() => { routeChange(`/create-user`) }}>Create User</Button>
                    }
                    <Button type="button" className="bulk-unsubscribe-btn" onClick={() => { routeChange(`/bulk-unsubscribe-users`) }}>Bulk Unsubscribe Users</Button>
                </div>
                <div className="search-box-main-wrapper">
                    <div className="search-description">
                        Enter any search criteria using one or more of the following options, then press
                        enter.
                    </div>
                    {
                        displayErrorMessage &&
                        <div id="divErrorMessage" className="search-emptyfield-error display-block">
                            <span className="error-red-icon"></span>

                            <span id="validatorSearchForm">{errorMessage}</span>
                        </div>
                    }

                    <Form onSubmit={handleSubmit} noValidate>
                        <div style={{ display: "flex" }}>
                            <Form.Group size="lg" controlId="uniqueId" >
                                <Form.Label>Unique ID #</Form.Label>
                                <Form.Control autoFocus type="text" value={uniqueId} onChange={(e) => setUniqueId(e.target.value)} className={"textField " + invalidUIDClassName} maxLength="19" />
                            </Form.Group>
                            <Form.Group size="lg" controlId="email" className="column">
                                <Form.Label>Email Address</Form.Label>
                                <Form.Control type="email" value={email} onChange={(e) => setEmail(e.target.value)} className={"textField " + invalidEmailClassName} maxLength="255" />
                            </Form.Group>
                        </div>
                        <div style={{ display: "flex", paddingTop: "10px" }}>
                            <Form.Group size="lg" controlId="FirstName" >
                                <Form.Label>First Name</Form.Label>
                                <Form.Control type="text" value={firstName} onChange={(e) => setFirstName(e.target.value)} className="textField" maxLength="255" />
                            </Form.Group>
                            <Form.Group size="lg" controlId="LastName" className="column">
                                <Form.Label>Last Name</Form.Label>
                                <Form.Control type="text" value={lastName} onChange={(e) => setLastName(e.target.value)} className="textField" maxLength="255" />
                            </Form.Group>
                        </div>
                        <br /><br />
                        <Button block="true" size="lg" type="submit">
                            Search
                        </Button>
                    </Form>
                </div>
                {
                    displayUserDetailsGrid ? (
                        <div className="UserDetailsGrid">
                            <CSVLink data={dataToDownload} filename={userReportFileName} target="_blank" ref={csvLink} />
                            <FetchData columns={columns}
                                data={userData}
                                pageIndex={pageNumber}
                                pageSize={pageSize}
                                totalPages={totalPages}
                                fetchData={fetchData} />
                        </div>
                    )
                        : (<p></p>)
                }
            </div>

        </>
    );
}
