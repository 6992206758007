import React from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';

const { Option } = Select;

const SelectField = (props) => {
	const {
		classnames,
		placeHolder,
		value,
		dataKey,
		onChange,
		isRequired,
		labelText,
		errMsg,
		onBlur,
		optionData,
		getContainer,
		defaultActiveFirstOption
	} = props;

	const mandatory = isRequired ? <span className="mandtory"> *</span> : '';
	const options = optionData.map((option) => (
		<Option key={option.value}>{option.label}</Option>
	));
	return (
		<>
			<div className="input-label">
				{labelText}
				{mandatory}
			</div>
			<Select
				showSearch
				placeholder={placeHolder}
				onChange={(inputValue) => onChange('', inputValue, dataKey)}
				onBlur={onBlur}
				value={value}
				className={`${classnames} default-input-style`}
				defaultActiveFirstOption={defaultActiveFirstOption}
				getPopupContainer={getContainer}
				filterOption={(input, option) =>
					option.children
						.toLowerCase()
						.indexOf(input.toLowerCase()) >= 0
				}
			>
				{options}
			</Select>
			{isRequired && errMsg && <div className="error">{errMsg}</div>}
		</>
	);
};

SelectField.defaultProps = {
	labelText: 'Dropdown',
	isRequired: false,
	placeHolder: 'Please select an option',
	errMsg: '',
	classnames: 'dropdown',
	onBlur: () => true,
	getContainer: () => document.body,
	defaultActiveFirstOption: true
};

SelectField.propTypes = {
	placeHolder: PropTypes.string,
	errMsg: PropTypes.string,
	labelText: PropTypes.string,
	isRequired: PropTypes.bool,
	onChange: PropTypes.func.isRequired,
	onBlur: PropTypes.func,
	value: PropTypes.string.isRequired,
	getContainer: PropTypes.func,
	classnames: PropTypes.string,
	dataKey: PropTypes.string.isRequired,
	optionData: PropTypes.arrayOf(PropTypes.object).isRequired,
	defaultActiveFirstOption: PropTypes.bool
};

export default SelectField;
