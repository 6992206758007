import React, { useState, useEffect } from 'react';
import "../../css/EditUser.css";

export default function Subscriptions(props) {

    const [newsLetter, setNewsLetter] = useState([]);
    const [alerts, setAlerts] = useState([]);
    const [informationEmail, setInformationEmail] = useState([]);
    const [unsubsribeAllSelected, setUnsubsribeAllSelected] = useState(false);
    const [selectAll, setSelectAll] = useState(false);
    const [isActive, setIsActive] = useState(false);

    function generateCBUI(items) {
        return items.map(newsLetter => {
            return (
                <>
                    <h3>{newsLetter.alertHeader}</h3>
                    <ul class="list halfWidthContent">
                        {newsLetter.alertItems.alerts.map(items => {
                            let selected = props.userSubscriptions.includes(items.alertCode.value);
                            let userSubscriptions = props.userSubscriptions;
                            const handleChange = (e, alertCode) => {
                                selected = !selected;
                                let index = props.userSubscriptions.indexOf(alertCode);
                                if (selected) {
                                    props.setGlobalOptOutAt(false);

                                    if (index === -1) {
                                        props.updateUserSubscriptions([...props.userSubscriptions, alertCode]);
                                    }
                                }
                                else if (!selected && index > -1) {
                                    userSubscriptions.splice(index, 1);
                                    props.updateUserSubscriptions([...userSubscriptions]);
                                }
                            }
                            return (
                                <li>
                                    <div class="listItemDiv">
                                        <div>
                                            <input type="checkbox" value={items.alertCode.value} checked={selected} onChange={(e) => { handleChange(e, items.alertCode.value) }} />
                                        </div>
                                        <div>
                                            {items.title.value}
                                        </div>

                                    </div>
                                </li>
                            )
                        })}
                    </ul>
                </>
            )
        })
    }
    useEffect(() => {
        setNewsLetter(generateCBUI(props.subscriptions.newsLetters));
        setAlerts(generateCBUI(props.subscriptions.alerts));
        let item = props.subscriptions.informationalMail;
        let selected = props.userSubscriptions.includes(item.alertCode.value);
        let userSubscriptions = props.userSubscriptions;
        const handleChange = (e, alertCode) => {
            selected = !selected;
            let index = props.userSubscriptions.indexOf(alertCode);

            if (selected && index === -1) {
                props.updateUserSubscriptions([...props.userSubscriptions, alertCode]);
            }

            else if (!selected && index > -1) {
                userSubscriptions.splice(index, 1);
                props.updateUserSubscriptions([...userSubscriptions]);
            }
        }
        setInformationEmail(
            <ul class="list">
                <li>
                    <div class="listItemDiv">
                        <div>
                            <input type="checkbox" value={item.alertCode.value} checked={selected} onChange={(e) => { handleChange(e, item.alertCode.value) }} />
                        </div>
                        <div>
                            {item.title.value}
                        </div>
                    </div>
                </li>
            </ul>
        );
}, [props.userSubscriptions, props.isPageReloadRequired])


const handleUnsubscribeCBChange = (e) => {

    let unsubsribeAll = !props.globalOptOutAt;
    //setUnsubsribeAllSelected(unsubsribeAll);
    if (unsubsribeAll) {
        props.updateUserSubscriptions([]);
        setSelectAll(false);
        props.setGlobalOptOutAt(true);
    }
    else {
        props.setGlobalOptOutAt(false);
    }
}

const handleSelectAllChange = (e) => {
    let selectAllCB = !selectAll;
    setSelectAll(selectAllCB);

    if (selectAllCB) {
        setUnsubsribeAllSelected(false);
        let allSubs = [];
        props.subscriptions.newsLetters.forEach(item => {
            item.alertItems.alerts.forEach(alertItem => {
                allSubs.push(alertItem.alertCode.value)
            })
        });
        props.subscriptions.alerts.forEach(item => {
            item.alertItems.forEach(alertItem => {
                allSubs.push(alertItem.alertCode.value)
            })
        });
        props.subscriptions.informationalMail.forEach(item => {
            allSubs.push(item.alertCode.value)
        });
        props.updateUserSubscriptions([...allSubs]);

    }
    else {
        props.updateUserSubscriptions([]);
    }
}

const onChangeValue = (e) => {
    console.log(e.target.value);
    props.setUserEmailPref(e.target.value)
}
return (
    <div id="Panel1">
        <div className="accordion">
            <div className="accordion-item">

                <div className="accordion-title" onClick={() => setIsActive(!isActive)}>
                    <div><h4>Newsletter Subscription</h4></div>
                    <div className="accordion-expand-symbol">{isActive ? '-' : '+'}</div>
                </div>
                {
                    isActive && <div className="panel accordion-content">
                        <ul class="list halfWidthContent select-all">
                            <li>
                                <input type="checkbox" checked={selectAll} onChange={(e) => { handleSelectAllChange(e) }} /> <label>Select All / Deselect All</label>
                            </li>
                        </ul>
                        <div id="ucSubscriptions_PanelSubscriptions">
                            <h4>NewsLetter</h4>
                            {newsLetter}

                            <h4>Alerts</h4>
                            {alerts}

                            <h4>Infromation Mail</h4>
                            {informationEmail}

                            <h4>
                                Email Preference
                            </h4>
                            <ul class="list" onChange={onChangeValue}>
                                <li>
                                    <input type="radio" value="0" name="emailPref" checked={props.userEmailPref === "0"} /> <label>Text</label>
                                </li>
                                <li>
                                    <input type="radio" value="1" name="emailPref" checked={props.userEmailPref === "1"} /> <label>Email</label>
                                </li>
                            </ul>
                            <h4>Unsubscribe</h4>
                            <input type="checkbox" checked={props.globalOptOutAt} onChange={(e) => { handleUnsubscribeCBChange(e) }} /> Remove me from all mckinsey.com mailings.
                        </div>
                    </div>
                }
            </div>
        </div>
    </div>
);
}

