const uniqueId = () => {
	let num = 0;
	return (prefix) => {
		const prefixValue = String(prefix) || '';
		num += 1;
		return prefixValue + num;
	};
};

const uuid = uniqueId();

export default uuid;
