import React from 'react';
import { Card, Skeleton } from 'antd';
import PropTypes from 'prop-types';
import Strings from '../../../Common/Constants';
import TabPaneView from '../TabPaneView/TabPaneView';
import './styles.scss';

const TabsContainer = ({
	handleTabChange,
	tabList,
	currentTabData,
	isLoading,
	tabActions,
	handleAction
}) => (
	<div className="tabs-container">
		<Card
			className="tab-card"
			title={Strings.DASHBOARD_TITLE}
			tabList={tabList}
			activeTabKey={currentTabData.id}
			onTabChange={(key) => handleTabChange(key)}
			headStyle={{ fontSize: '1.5rem' }}
		>
			{isLoading ? (
				<Skeleton />
			) : (
				<TabPaneView
					tabData={currentTabData}
					tabActions={tabActions}
					handleActionClick={handleAction}
				/>
			)}
		</Card>
	</div>
);

TabsContainer.defaultProps = {
	tabActions: null
};

TabsContainer.propTypes = {
	handleAction: PropTypes.func.isRequired,
	handleTabChange: PropTypes.func.isRequired,
	tabList: PropTypes.arrayOf(PropTypes.object).isRequired,
	currentTabData: PropTypes.objectOf(PropTypes.any).isRequired,
	isLoading: PropTypes.bool.isRequired,
	tabActions: PropTypes.objectOf(PropTypes.any)
};

export default TabsContainer;
