import React, { useState } from 'react';
import LoadingSpinner from '../Common/LoadingSpinner';
import "./bulkunsubscribe.css";
import { useHistory } from "react-router-dom";
import { Workbook } from 'exceljs';
import * as helper from '../../Utility/helper';
import { INVALID_FILE_EXTENSION, MAX_RECORDS_ERROR, UNABLE_TO_UNSUBSCRIBE_USERS } from '../../Common/Constants'


export default function UnsubscribeUsers(props) {
    let history = useHistory();
    const [emails, setEmails] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [isDisabled, setDisabled] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [isRequestProcessed, setIsRequestProcessed] = useState(false);
    const [processedRecordsCount, setProcessedRecordsCount] = useState(0);
    const [failedRecordsCount, setFailedRecordsCount] = useState(0);
    const [errors, setErrors] = useState([]);

    const templateExcel = [{
        "Email": "Email of the User"
    }];

    function handleFileUpload(event) {
        const reader = new FileReader();
        let file = event.target.files[0];
        let fileExtension = getFileExtension(file.name);
        if (fileExtension !== 'xls' && fileExtension !== 'xlsx') {
            setErrorMessage(INVALID_FILE_EXTENSION);
            setDisabled(true);
        }
        else {
            setErrorMessage('');
            setDisabled(false);
            reader.onload = async (e) => {
                const buffer = e.target.result;
                const workbook = new Workbook();
                await workbook.xlsx.load(buffer);
                let userEmails = [];
                workbook.eachSheet((worksheet, sheetId) => {
                    let lastRow = worksheet.actualRowCount;
                    if (lastRow > 351) {
                        setErrorMessage(MAX_RECORDS_ERROR);
                    }
                    for (let row = 2; row <= lastRow; row++) {
                        let cellValue = worksheet.getRow(row).getCell(1).value;
                        if (cellValue !== null && cellValue !== undefined) {
                            userEmails.push(cellValue.text ?? cellValue);
                        }
                    }
                });
                setEmails(userEmails);
            };
            reader.readAsArrayBuffer(file);
        }
    }


    function getFileExtension(fileName) {
        let fileNameArray = fileName.split('.');
        let extension = fileNameArray[fileNameArray.length - 1];
        return extension;
    }

    function handleSubmit() {
        setIsLoading(true);
        const headers = {
            "content-type": "application/json;",
        };

        let request = {
            "emailIds": emails
        }
        props.Post('/user/BulkUnsubscribe', request, headers).then(response => {
            setIsRequestProcessed(true);
            setProcessedRecordsCount(response.processed);
            setFailedRecordsCount(response.failed);

            if (response.errors.length > 0) {
                let errorDetails = response.errors.map(error => {
                    return (
                        <tr>
                            <td>{error.email}</td><td>{error.message}</td>
                        </tr>)
                })

                setErrors(errorDetails);
            }
            setIsLoading(false);
        }).catch(error => {
            setErrorMessage(UNABLE_TO_UNSUBSCRIBE_USERS);
            setIsLoading(false);
        })
    }
    function downloadTemplate() {
        let fileName = "UnsubscribeBulkUserTemplate";
        helper.ExportDataToExcel(templateExcel, fileName);
        setIsRequestProcessed(false);
    }
    if (isLoading) return <LoadingSpinner />;

    return (
        <>
            <div className="bulk-unsubscribe-container container">
                <h4>Unsubscribe bulk users</h4>
                <p>&nbsp;</p>

                <input type="file" name="FileUpload" id="FileUpload" onChange={handleFileUpload} />
                <span id="lblFileFormat">(Please choose an excel file in .xls or .xslx format)</span>
                &nbsp;&nbsp;
                <br />
                <br />
                <span className="downloadTemp"><button onClick={downloadTemplate}>Click Here To Download Template</button></span>
                <br />
                <br />
                <div className="edit-unsubscribe-button">
                    <input type="submit" name="btnUploadBulkUnsubscribe" value="Upload Bulk Users" id="btnUploadBulkUnsubscribe" className="submit" onClick={handleSubmit} disabled={isDisabled} />&nbsp;&nbsp;&nbsp;&nbsp;
                    <input type="submit" name="btnDiscardChanges" value="Back To Search" id="btnDiscardChanges" className="cancel" onClick={() => { history.push('/home') }} />
                </div>
            </div>

            {
                errorMessage &&
                <div className="bulk-unsubscribe-container container">
                    <span id="lblError" className="error-text">{errorMessage}</span>
                </div>
            }

            {
                !errorMessage && isRequestProcessed &&
                <div className="bulk-unsubscribe-container container" id="divResult" display="block">
                    <h4>Result:</h4>
                    <span id="lblProcessed">Processed: </span>
                    <input name="txtProcessed" type="text" readOnly="readOnly" id="txtProcessed" className="result-label" value={processedRecordsCount} />

                    <span id="lblFailed">Failed: </span>
                    <input name="txtFailed" type="text" readOnly="readOnly" id="txtFailed" className="result-label" value={failedRecordsCount} />

                    <br />
                    <br />

                    {
                        errors.length !== 0 &&
                        <table className="Grid" border="1" id="GridView1" style={{ borderCollapse: 'collapse' }}>
                            <tbody>
                                <tr>
                                    <th scope="col">Email</th><th scope="col">Message</th>
                                </tr>
                                {errors}
                            </tbody>
                        </table>
                    }

                </div>
            }
        </>
    );
}
