import React from 'react';
import PropTypes from 'prop-types';
import Modal from '../../Common/Modal/Modal';
import ManageAdmin from '../ManageAdmin/ManageAdmin';
import AddPractice from '../AddPractice/AddPractice';
import DeletePortal from '../DeletePortal/DeletePortal';

const DashboardPortalManager = ({
	portalState,
	handlePortalClose,
	updateTabList,
	Get,
	Post
}) => {
	let portal;

	const { isPortalOpen, activeTab, portalType, portalData } = portalState;
	switch (portalType.toLowerCase()) {
		case 'create':
			//eslint-disable-next-line no-unused-expressions
			activeTab === 'CellTab'
				? (portal =(
						<AddPractice
							onCancel={handlePortalClose}
							updateTabList={updateTabList}
							Get={Get}
							Post={Post}
						/>
				  )
                  )
				: (
					portal = (
						<ManageAdmin
							onCancel={handlePortalClose}
							updateTabList={updateTabList}
							Get={Get}
							Post={Post}
						/>
				  )
				  );
			break;
		case 'edit':
			activeTab === 'CellTab'
				? (portal = (
					<AddPractice
						buData={portalData}
						type={portalType}
						onCancel={handlePortalClose}
						updateTabList={updateTabList}
						Get={Get}
						Post={Post}
					/>
				  ))
				: (
                    portal = (
					<ManageAdmin
						adminData={portalData}
						type={portalType}
						onCancel={handlePortalClose}
						updateTabList={updateTabList}
						Get={Get}
						Post={Post}
					/>
				  )
                );
			break;
		case 'delete':
			portal = (
				<DeletePortal
					closePortal={handlePortalClose}
					activeTab={activeTab}
					updateTabList={updateTabList}
					data={portalData}
					Get={Get}
					Post={Post}
				/>
			);
			break;
		default:
	}

	return (
		<Modal
			defaultShow={isPortalOpen}
			onCancel={handlePortalClose}
			title={portalType}
		>
			{portal}
		</Modal>
	);
};

DashboardPortalManager.propTypes = {
	portalState: PropTypes.objectOf(PropTypes.any).isRequired,
	handlePortalClose: PropTypes.func.isRequired,
	updateTabList: PropTypes.func.isRequired,
	Get:PropTypes.func.isRequired,
	Post:PropTypes.func.isRequired

};

export default DashboardPortalManager;
