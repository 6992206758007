import React from 'react';
import { useTable, usePagination, useSortBy } from 'react-table';
import "../../css/FetchData.css";

export default function FetchData(props) {

    const columns = props.columns;
    const data = props.data;
    const pageNumber = props.pageIndex;
    const recordsPerPage = props.pageSize;
    const totalPages = props.totalPages;
    let fetchData = props.fetchData;

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,

        state: { pageIndex },
    } = useTable({
        columns,
        data,
        manualPagination: true,
        pageCount: totalPages,
        initialState: { pageIndex: pageNumber, pageSize: recordsPerPage },
    },
        useSortBy,
        usePagination
    )

    function exportToExcel() {
        fetchData(0, true);
    }

    function disableNext() {
        let disableButton = (pageIndex + 1 === pageCount) ? true : false;
        return disableButton;
    }

    function disablePrevious() {
        let disableButton = (pageIndex - 1 < 0) ? true : false;
        return disableButton;
    }
    return (
        <div>
            {
                data.length === 0 ?
                    (
                        <p>No Records Found</p>
                    ) :
                    (
                        <>
                            <p className="download-cvs">
                                <input type="submit" name="exportToExcel" value="Download results to CSV" onClick={exportToExcel} id="exportToExcel" />
                            </p>
                            <table className='table' {...getTableProps()}>
                                <thead>
                                    {headerGroups.map(headerGroup => (
                                        <tr {...headerGroup.getHeaderGroupProps()}>
                                            {headerGroup.headers.map(column => (
                                                <th {...column.getSortByToggleProps()}>
                                                    {column.render('Header')}
                                                </th>
                                            ))}
                                        </tr>
                                    ))}
                                </thead>
                                <tbody {...getTableBodyProps()}>
                                    {page.map((row, i) => {
                                        prepareRow(row)
                                        return (
                                            <tr {...row.getRowProps()}>
                                                {row.cells.map(cell => {
                                                    return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                                })}
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                            <ul className="pagination">
                                <li className={disablePrevious() ? "page-item is-disabled" : "page-item"} onClick={() => {
                                    gotoPage(0)
                                    fetchData(0, false);
                                }}
                                >
                                    <button className="page-link">First</button>
                                </li>
                                <li className={disablePrevious() ? "page-item is-disabled" : "page-item"} onClick={() => {
                                    previousPage()
                                    fetchData(pageIndex - 1, false);
                                }}
                                >
                                    <button className="page-link">{'Previous'}</button>
                                </li>
                                <li className={disableNext() ? "page-item is-disabled" : "page-item"} onClick={() => {
                                    nextPage()
                                    fetchData(pageIndex + 1, false);
                                }}
                                >
                                    <button className="page-link">{'Next'}</button>
                                </li>
                                <li className={disableNext() ? "page-item is-disabled" : "page-item"} onClick={
                                    () => {
                                        gotoPage(pageCount - 1)
                                        fetchData(pageCount - 1, false);
                                    }}
                                >
                                    <button className="page-link">Last</button>
                                </li>
                                <li>
                                    <button className="page-link">
                                        Page{' '}
                                        <strong>
                                            {pageIndex + 1} of {pageOptions.length}
                                        </strong>{' '}
                                    </button>
                                </li>
                            </ul>

                        </>
                    )}
        </div>
    );
}
