import React, { useState, useRef, useEffect } from "react";
import Modal from "../Common/Modal/Modal";
import { Space, Divider } from "antd";
import Button from "../Common/Button/Button";
import SelectField from "../Common/Select/Select";
import InputField from "../Common/Input/Input";
import Typeahead from "../Common/Typeahead/Typeahead";
import CheckboxGroup from "../Common/CheckboxGroup/CheckboxGroup";
import { isValidEmail, isEmpty } from "../../Utility/validation";
import { sanitizeInput } from "../../Utility/helper";
import { userApiConfig } from "../../Utility/apiConfig";
import Strings, { jobTitles, DUPLICATE_USER_EMAIL, UNABLE_TO_CREATE_USER, USER_CREATION_NOT_ALLOWED } from "../../Common/Constants";
import './style.css'

export default function CreateUser(props) {


    const handlePortalClose = (id = "") => {
        /* redirect to Search.aspx */
        // eslint-disable-next-line no-restricted-globals

        if (id) {
            props.history.push(`/home?id=${id}`);
        }
        else {
            props.history.push(`/home`);
        }

        return false;
    };

    const addUserRef = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const [formClass, setFormClass] = useState("add-practice");

    const [formData, setFormData] = useState({
        firstName: "",
        lastName: "",
        email: "",
        company: "",
        title: "",
        country: "",
        bFunction: "",
        businessUnit: "",
        firstNameErrorMesage: "",
        lastNameErrorMessage: "",
        titleErrorMessage: "",
        countryErrorMessage: "",
        bFunctionErrorMessage: "",
        companyErrorMessage: "",
        emailAddressErrorMessage: "",
        buErrorMessage: "",
        roleErrorMessage: "",
        genericErrorMessage: "",
    });

    const [buOptions, setBuOptions] = useState([]);
    const [roleOptions, setRoleOptions] = useState([]);
    const [checkedList, setCheckedList] = useState([]);
    const [buOptionsData, setBuOptionsData] = useState([]);
    const [countryList, setCountryList] = useState([]);
    const [bFunctionList, setBFunctionList] = useState([]);

    const {
        firstName,
        lastName,
        company,
        title,
        country,
        bFunction,
        email,
        businessUnit,
        firstNameErrorMesage,
        lastNameErrorMessage,
        titleErrorMessage,
        countryErrorMessage,
        bFunctionErrorMessage,
        companyErrorMessage,
        emailAddressErrorMessage,
        buErrorMessage,
        roleErrorMessage,
        genericErrorMessage,
    } = formData;

    const handleInputChange = (event, value, dataKey) => {
        let inputValue = value;
        let key = dataKey;
        if (event) {
            const inputElement = event.target;
            inputValue = inputElement.value;
            key = inputElement.getAttribute("datakey");
        }

        setFormData({ ...formData, [key]: inputValue });
    };

    const handleBuInputChange = (event, value, dataKey) => {
        handleInputChange(event, value, dataKey);
        setCheckedList([]);
        if (buOptionsData.length > 0) {
            let buObj = buOptionsData.find((item) => item.name === value);
            if (buObj) {
                setRoleOptions(buObj.roles);
            } else {
                setRoleOptions([]);
            }
        }
    };

    const handleRoleChange = (rolesList) => {
        setCheckedList(rolesList);
    };

    const getErrorMessage = (type, msg) => {
        if (type === "email") {
            return formData[type] && isValidEmail(formData[type]) ? "" : msg;
        }
        if (type === "checkboxGroup") {
            return checkedList.length > 0 ? "" : msg;
        }
        return isEmpty(formData[type]) ? msg : "";
    };

    const handleValidation = (event, dataKey) => {
        const key = dataKey || event?.target?.getAttribute("datakey");
        const dataUtil = {
            firstName: {
                msg: Strings.FIRST_NAME_REQUIRED_ERROR,
                key: "firstNameErrorMesage",
            },
            lastName: {
                msg: Strings.LAST_NAME_REQUIRED_ERROR,
                key: "lastNameErrorMessage",
            },
            title: {
                msg: Strings.TITLE_REQUIRED_ERROR,
                key: "titleErrorMessage",
            },
            country: {
                msg: Strings.COUNTRY_REQUIRED_ERROR,
                key: "countryErrorMessage",
            },
            bFunction: {
                msg: Strings.BFUNCTION_REQUIRED_ERROR,
                key: "bFunctionErrorMessage",
            },
            company: {
                msg: Strings.COMPANY_REQUIRED_ERROR,
                key: "companyErrorMessage",
            },
            email: {
                msg: Strings.EMAIL_REQUIRED_ERROR,
                key: "emailAddressErrorMessage",
            },
            businessUnit: {
                msg: Strings.BU_REQUIRED_ERROR,
                key: "buErrorMessage",
            },
            checkboxGroup: {
                msg: Strings.ROLE_REQUIRED_ERROR,
                key: "roleErrorMessage",
            },
        };

        const errorObj = {};

        if (!key) {
            Object.keys(dataUtil).forEach((field) => {
                const method = dataUtil[field];
                const error = getErrorMessage(field, method.msg);
                if (error !== "") errorObj[method.key] = error;
            });
        } else {
            const method = dataUtil[key];
            errorObj[method.key] = getErrorMessage(key, method.msg);
        }

        setFormData({
            ...formData,
            ...errorObj,
        });

        return Object.keys(errorObj).length === 0;
    };

    const generateRolesArr = () => {
        let rolesArr = [];
        if (checkedList.length > 0) {
            checkedList.forEach((item) => {
                let unitNameLowerCase = businessUnit.toLowerCase();
                let sanitizedRoleName = item.replace(/\s/g, '').toLowerCase();
                rolesArr.push(`${unitNameLowerCase}\\${sanitizedRoleName}`);
            });
        }
        return rolesArr;
    };

    const handleFormSubmit = () => {
        if (handleValidation()) {
            setIsLoading(true);
            const payload = {
                'firstName': sanitizeInput(firstName),
                'lastName': sanitizeInput(lastName),
                'title': title,
                'company': sanitizeInput(company),
                'email': email,
                'subscriptions': [],
                'occasionalEmail': false,
                'sitecoreRolesToBeAdded': generateRolesArr(),
                'country': country,
                'businessFunction': bFunction
            };
            const headers = {
                "content-type": "application/json;",
            };
            props.Post('/user/CreateUser', payload, headers).then(
                (response) => {
                    /* redirect to search page */
                    setIsLoading(false);
                    handlePortalClose(response.id);
                }
            ).catch((error) => {
                /* In case email Address already exists */
                error.text().then((error) => {
                    var errorJson = JSON.parse(error);

                    if (errorJson && errorJson.message === "1004") {
                        setFormData({
                            ...formData,
                            emailAddressErrorMessage: DUPLICATE_USER_EMAIL,
                        });
                    }
                    else {
                        setFormData({
                            ...formData,
                            genericErrorMessage: UNABLE_TO_CREATE_USER,
                        });
                    }
                    setIsLoading(false);
                }).catch((error) => {
                    setIsLoading(false);
                    setFormData({
                        ...formData,
                        genericErrorMessage: UNABLE_TO_CREATE_USER,
                    });
                })
            });
        }
    };

    useEffect(() => {

        let practiceIds = localStorage.getItem('practiceIds');
        if (!practiceIds) {
            setFormClass("add-practice disabled")
            setFormData({
                ...formData,
                genericErrorMessage: USER_CREATION_NOT_ALLOWED,
            });
        }

        let buDetailseSearchParam = new URLSearchParams();
        buDetailseSearchParam.append("buIds", practiceIds);
        let headers = {};
        props.Get('businessunit/getBUDetails?', buDetailseSearchParam, headers).then((data) => {
            setBuOptionsData(data);
            let buOptionsObj = data || [];
            if (buOptionsObj.length > 0) {
                let buArray = buOptionsObj.map((item) => {
                    return { label: item['displayName'], value: item['name'] };
                });
                buArray.unshift({
                    label: 'Please select business unit',
                    value: ''
                });
                setBuOptions(buArray);
            }
        })
        let urlSearchParams = new URLSearchParams();
        props.Get('/metadata', urlSearchParams, headers).then((data) => {
            let countryOptionObj = data.countryData || [];
            if (countryOptionObj.length > 0) {
                let buArray = countryOptionObj.map((item) => {
                    return { label: item['description'], value: item['code'] };
                });
                buArray.unshift({
                    label: 'Please select country',
                    value: ''
                });
                setCountryList(buArray);
            }

            let bFunctionOptionObj = data.bFunctionData || [];
            if (bFunctionOptionObj.length > 0) {
                let buArray = bFunctionOptionObj.map((item) => {
                    return { label: item['description'], value: item['code'] };
                });
                buArray.unshift({
                    label: 'Please select business function',
                    value: ''
                });
                setBFunctionList(buArray);
            }
        })

    }, []);
    return (
        <Modal defaultShow onCancel={() => { handlePortalClose("") }} title="Create User">
            <div className={formClass} ref={addUserRef}>
                {
                    genericErrorMessage &&

                    <p class="error">{genericErrorMessage}</p>
                }
                <InputField
                    labelText={Strings.FIRST_NAME_LABEL}
                    placeHolder={Strings.FIRST_NAME_PLACEHOLDER_TEXT}
                    isRequired
                    errMsg={firstNameErrorMesage}
                    value={firstName}
                    dataKey="firstName"
                    onChange={handleInputChange}
                    reqValidationMessage={Strings.FIRST_NAME_REQUIRED_ERROR}
                />
                <InputField
                    labelText={Strings.LAST_NAME_LABEL}
                    placeHolder={Strings.LAST_NAME_PLACEHOLDER_TEXT}
                    isRequired
                    onChange={handleInputChange}
                    errMsg={lastNameErrorMessage}
                    value={lastName}
                    dataKey="lastName"
                    reqValidationMessage={Strings.LAST_NAME_REQUIRED_ERROR}
                />

                <Typeahead
                    labelText={Strings.COMPANY_NAME_LABEL}
                    placeHolder={Strings.COMPANY_NAME_PLACEHOLDER_TEXT}
                    isRequired
                    onBlur={(e) => handleValidation(e, "company")}
                    errMsg={companyErrorMessage}
                    value={company}
                    setValue={handleInputChange}
                    dataKey="company"
                    dataType="companies"
                    apiUrl={userApiConfig.companies}
                    getContainer={() => addUserRef.current}
                    Get={props.Get}
                />

                <SelectField
                    labelText={Strings.TITLE_LABEL}
                    isRequired
                    onChange={handleInputChange}
                    onBlur={(e) => handleValidation(e, "title")}
                    errMsg={titleErrorMessage}
                    value={title}
                    dataKey="title"
                    optionData={jobTitles}
                    getContainer={() => addUserRef.current}
                />

                <SelectField
                    labelText={Strings.COUNTRY_LABEL}
                    isRequired
                    onChange={handleInputChange}
                    onBlur={(e) => handleValidation(e, "country")}
                    errMsg={countryErrorMessage}
                    value={country}
                    dataKey="country"
                    optionData={countryList}
                    getContainer={() => addUserRef.current}
                />

                <SelectField
                    labelText={Strings.BFUNCTION_LABEL}
                    isRequired
                    onChange={handleInputChange}
                    onBlur={(e) => handleValidation(e, "bFunction")}
                    errMsg={bFunctionErrorMessage}
                    value={bFunction}
                    dataKey="bFunction"
                    optionData={bFunctionList}
                    getContainer={() => addUserRef.current}
                />

                <InputField
                    labelText={Strings.EMAIL_ADDRESS_LABEL}
                    placeHolder={Strings.USER_EMAIL_ADDRESS_PLACEHOLDER_TEXT}
                    isRequired
                    errMsg={emailAddressErrorMessage}
                    fieldType="email"
                    value={email}
                    dataKey="email"
                    onChange={handleInputChange}
                    reqValidationMessage={Strings.EMAIL_REQUIRED_ERROR}
                    fieldValidationMessage={Strings.EMAIL_ADDRESS_INVALID_ERROR}
                />

                {buOptions.length > 0 && (
                    <SelectField
                        labelText={Strings.BUSINESS_UNIT_TAB_TITLE}
                        isRequired
                        onChange={handleBuInputChange}
                        onBlur={(e) => handleValidation(e, "businessUnit")}
                        errMsg={buErrorMessage}
                        value={businessUnit}
                        dataKey="businessUnit"
                        optionData={buOptions}
                        getContainer={() => addUserRef.current}
                    />
                )}

                {roleOptions.length > 0 && (
                    <CheckboxGroup
                        labelText={Strings.CUSTOM_ROLE_LABEL}
                        options={roleOptions}
                        value={checkedList}
                        onChange={handleRoleChange}
                        isRequired
                        errMsg={roleErrorMessage}
                        type="checkboxGroup"
                        dataKey="checkboxGroup"
                    />
                )}

                <div>
                    <Divider />
                    <Space size="middle">
                        <Button
                            type="Create"
                            clickHandler={handleFormSubmit}
                            isDisabled={isLoading}
                            isLoading={isLoading}
                        />
                        <Button
                            type="cancel"
                            clickHandler={() => {
                                setIsLoading(true);
                                handlePortalClose("");
                            }}
                            isDisabled={isLoading}
                            isLoading={isLoading}
                        />
                    </Space>
                </div>
            </div>
        </Modal>
    );
}
