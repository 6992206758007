import React, { useState } from 'react';
import { AutoComplete, Input } from 'antd';
import PropTypes from 'prop-types';

let timeout;
let currentValue;

function fetchSuggestions(apiUrl, dataType, value, callback,Get) {
	if (timeout) {
		clearTimeout(timeout);
		timeout = null;
	}

	currentValue = value;

	function callSuggestionApi() {
		let termSource=new URLSearchParams();
		termSource.append("term",value);
		Get(apiUrl, termSource
		, {}).then(response => {
			if (currentValue === value) {
				const records = response?.data?.companies || [];
				const data = [];

				records.forEach((record) => {
					data.push({
						value: record
					});
				});

				callback(data);
			}
		});
	}

	timeout = setTimeout(callSuggestionApi, 300);
}

const SearchInput = ({
	isRequired,
	classnames,
	labelText,
	placeHolder,
	apiUrl,
	dataType,
	value,
	setValue,
	dataKey,
	errMsg,
	onBlur,
	getContainer,
    Get
}) => {
	const [data, setData] = useState([]);

	const handleSearch = (searchQuery) => {
		if (searchQuery) {
			fetchSuggestions(apiUrl, dataType, searchQuery, setData,Get);
		} else {
			setData([]);
		}
	};

	const mandatory = isRequired ? <span className="mandtory"> *</span> : '';

	return (
		<>
			<div className="input-label">
				{labelText}
				{mandatory}
			</div>
			<AutoComplete
				options={data}
				value={value}
				placeholder={placeHolder}
				className={`${classnames} default-input-style`}
				onSearch={handleSearch}
				onBlur={onBlur}
				onChange={(inputValue) => setValue('', inputValue, dataKey)}
				getPopupContainer={getContainer}
				notFoundContent={null}
			>
				<Input
					autoComplete="off"
					autoCorrect="off"
					spellCheck="false"
				/>
			</AutoComplete>
			{isRequired && errMsg && <div className="error">{errMsg}</div>}
		</>
	);
};

SearchInput.defaultProps = {
	placeHolder: 'Please provide an input for suggestions',
	value: undefined,
	dataKey: 'autocomplete',
	errMsg: 'Please enter an input for suggestions',
	onBlur: () => true,
	classnames: 'dropdown',
	getContainer: () => document.body
};

SearchInput.propTypes = {
	apiUrl: PropTypes.string.isRequired,
	setValue: PropTypes.func.isRequired,
	dataType: PropTypes.string.isRequired,
	labelText: PropTypes.string.isRequired,
	isRequired: PropTypes.bool.isRequired,
	placeHolder: PropTypes.string,
	value: PropTypes.string,
	dataKey: PropTypes.string,
	errMsg: PropTypes.string,
	onBlur: PropTypes.func,
	getContainer: PropTypes.func,
	classnames: PropTypes.string
};

export default SearchInput;
