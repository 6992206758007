import React from 'react';
import { Checkbox } from 'antd';

const AntCheckboxGroup = Checkbox.Group;

const CheckboxGroup = (props) => {
    const { 
        labelText,
        options, 
        value, 
        onChange,
        isRequired,
        errMsg,
        dataKey,
        type
    } = props;

    const mandatory = isRequired ? <span className="mandtory"> *</span> : '';

    return (
        <>
            <div className="input-label">
                {labelText}
                {mandatory}
            </div>
            <AntCheckboxGroup
                options={options}
                value={value}
                onChange={onChange}
                datakey={dataKey}
                type={type}
            />
            {errMsg !== '' && value.length === 0 && (
				<div className="error">{errMsg}</div>
			)}
        </>
    )
}

export default CheckboxGroup;