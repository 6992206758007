function getUserAPIHostURI() {
	/* Use uat userservice on QA Member Admin tool origin otherwise use prod config */
	return window.location.origin.includes('qa')
		? 'uat-api.mckinsey.com'
		: 'prd-api.mckinsey.com';
}
    
    export const apiConfig = {
        CellTab: {
            listApi: 'businessunit',
            createApi: 'api/BusinessUnits',
            checkUnit: 'api/BusinessUnits/checkUnitName'
        },
        AdminTab: {
            listApi: '/user/MemberAdmin/AllUsers',
            createApi: '/user/MemberAdmin/createAdmin',
            checkUserName: '/user/MemberAdmin/checkAdminExists'
        }
    };
    export const userApiConfig = {
        create: '/user/CreateUser',
        companies: `https://${getUserAPIHostURI()}/v2/lookup/company?`
    };
    