import React, { useState, useEffect } from 'react';
import { Redirect, Route, useHistory, useLocation } from 'react-router-dom';
import { ValidateToken, validateRoles } from '../../Utility/TokenValidator';
import * as Constants from '../../Common/Constants';
import jwt from 'jwt-decode';
import LoadingSpinner from './LoadingSpinner';
import NavMenu from '../Header/NavMenu';
import { useOktaAuth } from '@okta/okta-react';

const PrivateRoute = ({ component: Component, ...rest }) => {
    const [tab] = useState(rest.tab);
    const [auth, setAuth] = useState(false);
    const [isTokenValidated, setIsTokenValidated] = useState(false);
    const { authState, oktaAuth } = useOktaAuth();
    const mckAdminPage = ['/home', '/create-user', '/create-bulk-user', '/managebu', '/bulk-unsubscribe-users'];
    const formAdminPage = ['/form-data'];
    const [token, setToken] = useState('');

    let history = useHistory();
    let errorText = Constants.UNAUTHORIZED_ERROR_MESSAGE;
    let location = useLocation();

    useEffect(() => {
        async function validateToken() {
            if (!authState || !authState.isAuthenticated) {
              localStorage.clear();
              setIsTokenValidated(true);
              return;
            }
          
            const accessToken = authState.accessToken.accessToken || oktaAuth.getAccessToken();
            setToken(accessToken);

            const roleId = ValidateToken(accessToken);
            const isRoleValidForCurrentPath = await validateAuthBasedOnRole(roleId);            
            if (roleId === 1001 || (roleId.adminRole === 0 && roleId.formAdminRole === 0) || !isRoleValidForCurrentPath) {             
              localStorage.clear();
              setIsTokenValidated(true);
              return;
            }
          
            setAuth(isRoleValidForCurrentPath);
            localStorage.setItem("roleId", JSON.stringify(roleId.adminRole));
            localStorage.setItem("formAdminRoleId", JSON.stringify(roleId.formAdminRole));
            setIsTokenValidated(true);
        }
        
        async function validateAuthBasedOnRole(roleId) {
            const isAdmin = roleId.adminRole !== 0;
            const isFormAdmin = roleId.formAdminRole === 1 && formAdminPage.indexOf(location.pathname) > -1;
            const isMckAdmin = isAdmin && (mckAdminPage.indexOf(location.pathname) > -1 || location.pathname.includes('/details'));
            const isUserAuthorised= isFormAdmin || isMckAdmin || (isAdmin && roleId.formAdminRole !== 0);

            // if(!isUserAuthorised){
            //     await oktaAuth.signOut();
            // }
            return isUserAuthorised;
        }

        validateToken();
    }, []);

    // Function to be passed in props to all secure components and to be used to make all Get API calls from secured components.
    function Get(apiEndPoint, queryParams, headers) {
        headers = AddCommonHeadersToRequest(headers);
        let requestInfo = queryParams ? apiEndPoint + queryParams : apiEndPoint;
        return fetch(requestInfo, {
            headers: headers,
            credentials: 'include',
        })
            .then(response => {
                UpdateTokensFromHeader(response);
                if (response.ok) {
                    return response.json();
                }
                else {
                    return Promise.reject(response.status);
                }
            }).then(data => {
                // Error code 1001 means that the refresh token is expired and user should be redirected to login page.
                if (data.errorCode === 1001) {
                    history.push({ pathname: '/signin', state: { from: history.location, errorMessage: Constants.UNAUTHORIZED_ERROR_MESSAGE } });
                }
                else {
                    return Promise.resolve(data);
                }
            })
    }

    // Function to be passed in props to all secure components and to be used to make all POST API calls from secured components.
    function Post(apiEndPoint, requestBody, headers) {
        headers = AddCommonHeadersToRequest(headers);
        return fetch(apiEndPoint, {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(requestBody),
            headers: headers,
        })
            .then(response => {
                UpdateTokensFromHeader(response);
                if (response.ok) {
                    return response.json();
                }
                else {
                    return Promise.reject(response);
                }
            }).then(data => {
                // Error code 1001 means that the refresh token is expired and user should be redirected to login page.
                if (data.errorCode === 1001) {
                    history.push({ pathname: '/', state: { from: history.location, errorMessage: Constants.UNAUTHORIZED_ERROR_MESSAGE } });
                    return Promise.reject(data);
                }
                else {
                    return Promise.resolve(data);
                }
            })
    }

    function AddCommonHeadersToRequest(headers) {
        if (token) {
            headers.accessToken = token;
        }
        return headers;
    }

    function UpdateTokensFromHeader(response) {
        if (response.headers.get('access_token')) {
            let accessToken = response.headers.get('access_token');
            localStorage.setItem("token", accessToken);
        }
        if (response.headers.get('refresh_token')) {
            let refreshToken = response.headers.get('refresh_token');

            localStorage.setItem("refreshToken", refreshToken);
        }
        if (response.headers.get('ARRAffinity')) {
            localStorage.setItem("ARRAffinity", response.headers.get('ARRAffinity'));
        }
    }
    if (!isTokenValidated) return <LoadingSpinner />;

    return (
        <Route
            {...rest}
            render=
            {
                props => {
                    return auth ? (<><NavMenu isLoginPage={false} tab={tab} /><Component {...props} Post={Post} Get={Get} /></>) : (<Redirect to={{ pathname: '/', state: { from: props.location, errorMessage: errorText, isUserAuthorised: false } }} />)
                }
            }
        />
    )
}
export default PrivateRoute