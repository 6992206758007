import React from 'react';
import PropTypes from 'prop-types';
import { Space } from 'antd';
import Button from '../../Common/Button/Button';

const TableActions = ({ actions, data, handleActionClick }) => {
	return (
		<Space size="middle">
			{Object.keys(actions).map((actionKey) => (
				<Button
					type={actionKey}
					key={`${actionKey}Button`}
					clickHandler={() => handleActionClick(actionKey, data)}
				/>
			))}
		</Space>
	);
};

TableActions.propTypes = {
	actions: PropTypes.objectOf(PropTypes.any).isRequired,
	data: PropTypes.objectOf(PropTypes.any).isRequired,
	handleActionClick: PropTypes.func.isRequired,
};

export default TableActions;
