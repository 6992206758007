import React, { useState, useEffect } from 'react';
import { tabList, tabsViewData, tabActions } from './tabState';
import { apiConfig } from '../../../Utility/apiConfig';
import Tabs from '../../Common/tabs/Tabs'
import { listingDataModifier } from '../../../Utility/DataModifier';
import DashboardPortalManager from '../DashboardPortalManager/DashboardPortalManager'


export default function Dashboard(props){

    const [activeTab, setActiveTab] = useState(tabList[0].key);
	const [currentTabData, setCurrentTabData] = useState(
		tabsViewData[activeTab]
	);
	const [portalState, setPortalState] = useState({
		isPortalOpen: false,
		activeTab,
		portalType: '',
		portalData: null,
	});
	const [isLoading, setLoading] = useState(false);

    const handleCallToAction = (type, data) => {
		setPortalState({
			isPortalOpen: true,
			portalType: type,
			portalData: data,
			activeTab,
		});
	};

	const handlePortalClose = () => {
		setPortalState({
			...portalState,
			isPortalOpen: false,
		});
	};

	const setTabData = response => {
		setLoading(false);
		const tabData = { ...tabsViewData[activeTab] };
		tabData.data = listingDataModifier(activeTab, response);
		setCurrentTabData(tabData);
	}

	const getData = () => {
		const apiUrl = apiConfig[activeTab].listApi;
		setLoading(true);
		props.Get(apiUrl,new URLSearchParams(),{}).then((response) => {
			setTabData(response);
		}, () => {
			setTabData({ data: [] });
		});
	};

	useEffect(() => {
		getData();
	}, [activeTab]);

	return (
		<div className="container">
			<Tabs
				handleTabChange={setActiveTab}
				handleAction={handleCallToAction}
				tabList={tabList}
				isLoading={isLoading}
				currentTabData={currentTabData}
				tabActions={tabActions[activeTab]}
			/>
			{portalState.isPortalOpen && (
				<DashboardPortalManager
					portalState={portalState}
					setPortalState={setPortalState}
					handlePortalClose={handlePortalClose}
					updateTabList={getData}
					Get={props.Get}
					Post={props.Post}
				/>
			)}
		</div>
	);
}