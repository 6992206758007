import React, { Component } from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler } from 'reactstrap';
import '../../css/NavMenu.css';
import { Link, withRouter } from "react-router-dom";


class NavMenu extends Component {
    static displayName = NavMenu.name;

    constructor(props) {
        super(props);

        let tab1 = false;
        let tab2 = false;
        let tab3 = false;

        switch (props.tab) {
            case 1:
                tab1 = true;
                break;
            case 2:
                tab2 = true;
                break;
            case 3:
                tab3 = true;
                break;
            default:
                tab1 = true;
                break;
        }
        this.setState({ tab1Selected: tab1, tab2Selected: tab2, tab3Selected: tab3 })


        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.state = {
            collapsed: true,
            roleId: localStorage.getItem('roleId'),
            formRoleId: localStorage.getItem('formAdminRoleId'),
            email: localStorage.getItem('email'),
            tab1Selected: tab1,
            tab2Selected: tab2,
            tab3Selected: tab3
        };
    }

    toggleNavbar() {
        this.setState({
            toggleNavbar() {
                this.setState(prevState => ({
                    collapsed: !prevState.collapsed
                }));
            }
        });
    }
    render() {
        return (
            <header>
                <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" light>
                    <Container>
                        <NavbarBrand>MckAdmin</NavbarBrand>
                        <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
                        {!this.props.isLoginPage && this.state.email &&
                            <Collapse className="d-sm-inline-flex" navbar>
                                <ul className="navbar-nav flex-grow">
                                    {
                                        this.state.roleId !== '0' &&
                                        <li >
                                            <Link className={this.state.tab1Selected ? "menu-item tabSelected" : "menu-item"} to="/home">Search</Link>
                                        </li>
                                    }
                                    {
                                        this.state.roleId === '3' &&
                                        <li >
                                            <Link className={this.state.tab2Selected ? "menu-item tabSelected" : "menu-item"} to="/managebu">Manage Business Units</Link>
                                        </li>
                                    }
                                    {
                                        this.state.formRoleId === '1' &&
                                        <li >
                                                <Link className={this.state.tab3Selected ? "menu-item tabSelected" : "menu-item"} to="/form-data">Export Form Data</Link>
                                        </li>
                                    }
                                </ul>
                            </Collapse>
                        }
                        {/* {
                            !this.props.isLoginPage &&
                            <div className="logoutLink">
                                <a className="mr-auto" href="/signout">
                                    <img className="logoutimg" />
                                </a>
                            </div>
                        } */}
                    </Container>
                </Navbar>
            </header>
        );
    }
}
export default withRouter(NavMenu);