import React, { useState } from 'react';
import LoadingSpinner from '../Common/LoadingSpinner';
import "./CreateBulkUsers.css";
import { useHistory } from "react-router-dom";
import { Workbook } from 'exceljs';
import * as helper from '../../Utility/helper';

export default function CreateBulkUsers(props) {
    let history = useHistory();
    const [data, setData] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [isDisabled, setDisabled] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [isRequestProcessed, setIsRequestProcessed] = useState(false);

    const templateExcel = [{
        "Email": "Email of the User",
        "FirstName": "First Name of the User",
        "LastName": "Last Name of the User",
        "Title": "Title index in Number: (Get the id from titles of the metadata api)",
        "Company": "Company Name of the user",
        "Subscriptions": "Subscription Array : Comma separated (Get the alertCode from subscriptions-subscriptionData of the metadata api)",
        "SitecoreRolesToBeAdded": "Roles e.g - hss\\conference2023,mgi\\mediaagency : Comma separated",
        "SitecoreRolesToBeDeleted": "Roles e.g - hss\\conference2023,mgi\\mediaagency : Comma separated",
        "Country": "Code of the country, e.g - US ",
        "BusinessFunction": "Code number of the function, e.g - 3 "
    }];


    function handleFileUpload(event) {
        const reader = new FileReader();
        let file = event.target.files[0];
        let fileExtension = helper.getFileExtension(file.name);
        setIsRequestProcessed(false);

        if (fileExtension !== 'xls' && fileExtension !== 'xlsx') {
            setErrorMessage('Invalid extension. Please select valid excel file');
            setDisabled(true);
            return;
        }

        setErrorMessage('');
        setDisabled(false);
        reader.onload = async (e) => {
            const buffer = e.target.result;
            const workbook = new Workbook();
            await workbook.xlsx.load(buffer);
            let jsonData = [];
            workbook.eachSheet((worksheet, sheetId) => {
                worksheet.eachRow((row, rowNumber) => {
                    if (rowNumber > 1) { // Skip header row
                        let rowData = {};
                        row.eachCell((cell, colNumber) => {
                            let header = worksheet.getRow(1).getCell(colNumber).value;
                            if (['SitecoreRolesToBeAdded', 'SitecoreRolesToBeDeleted', 'Subscriptions'].includes(header)) {
                                rowData[header] = cell.value.split(',');
                            } else if (['Company', 'FirstName', 'LastName', 'BusinessFunction'].includes(header)) {
                                rowData[header] = cell.value.toString();
                            } else if (['Email'].includes(header)) {
                                rowData[header] = cell.value.text ?? cell.value.toString();
                            }
                            else {
                                rowData[header] = cell.value;
                            }
                        });
                        jsonData.push(rowData);
                    }
                });
            });
            setData(jsonData);
        };
        reader.readAsArrayBuffer(file);
    }

    function downloadTemplate() {
        let fileName = "CreateBulkUserTemplate";
        helper.ExportDataToExcel(templateExcel, fileName);
        setIsRequestProcessed(false);
    }

    function handleSubmit() {
        setIsLoading(true);
        const headers = {
            "content-type": "application/json;",
        };

        props.Post('/user/CreateBulkUser', data, headers).then(response => {
            setIsRequestProcessed(true);
            let today = new Date();
            let name = `ExportData_${today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate()}`;
            helper.ExportDataToExcel(response.Data, name);
            setIsLoading(false);
        }).catch(_error => {
            setIsLoading(false);
            setErrorMessage('Something Went wrong !! Please check the excel file once !!');
            setDisabled(true);
        })
    }

    if (isLoading) return <LoadingSpinner />;

    return (
        <>
            <div className="bulk-unsubscribe-container container">
                <h4>Create bulk users</h4>
                <p>&nbsp;</p>

                <input type="file" name="FileUpload" id="FileUpload" onChange={handleFileUpload} />
                <span id="lblFileFormat">(Please choose an excel file in .xls or .xslx format)</span>
                &nbsp;&nbsp;
                <br />
                <br />
                <span className="downloadTemp"><button target="_blank" onClick={downloadTemplate}>Click Here To Download Template</button></span>
                <br />
                <br />
                <div className="edit-unsubscribe-button">
                    <input type="submit" name="btnUploadBulkUnsubscribe" value="Create Bulk Users" id="btnUploadBulkUnsubscribe" className="submit" onClick={handleSubmit} disabled={isDisabled} />&nbsp;&nbsp;&nbsp;&nbsp;
                    <input type="submit" name="btnDiscardChanges" value="Back To Search" id="btnDiscardChanges" className="cancel" onClick={() => { history.push('/home') }} />
                </div>

                {
                    isRequestProcessed &&
                    <p> Records Created / Updated succesfully </p>
                }
            </div>
            {
                errorMessage &&
                <div className="bulk-unsubscribe-container container">
                    <span id="lblError" className="error-text">{errorMessage}</span>
                </div>
            }
        </>
    );
}
