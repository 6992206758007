import React from "react";
import { Route } from "react-router";
import HomeComponent from "./components/Search User/Home";
import FetchDataComponent from "./components/Search User/FetchData";
import ManageBusinessUnits from "./components/Manage BU/ManageBusinsessUnits";
import CreateUserComponent from "./components/Create User/CreateUser";
import CreateBulkUserComponent from "./components/CreateBulkUsers/CreateBulkUsers";
import GetFormDataComponent from "./components/Form Data/GetFormData";
import BulkUnsubscribeUsersComponent from "./components/BulkUnsubscribeUsers/BulkUnsubscribeUsers";
import EditUserComponent from "./components/Edit User/EditUser";
import LoginComponent from "./components/Login";
import SignOutComponent from "./components/Logout";
import { BrowserRouter, useHistory } from "react-router-dom";
import PrivateRoute from "./components/Common/PrivateRoute";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import { LoginCallback, Security, SecureRoute } from "@okta/okta-react";
// import LoginCallback from "./components/Common/LoginCallback";
import "./custom.css";

const oktaAuth = new OktaAuth({
  issuer: process.env.REACT_APP_OKTA_ISSUER.trim(),
  clientId: process.env.REACT_APP_OKTA_CLIENT_ID.trim(),
  redirectUri: window.location.origin + "/signin/callback",
  scopes:["mckinsey", "email", "openid"],
  disableHttpsCheck: false,
  withCredentials: true,
});
export default function App(props) {
  const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href");
  return (
    <BrowserRouter basename={baseUrl}>
      <Routes />
    </BrowserRouter>
  );
}

const Routes = (props) => {
  let history = useHistory();

  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    history.replace(toRelativeUrl(originalUri || "/", window.location.origin));
  };

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <Route exact path="/" component={LoginComponent} />
      <Route exact path="/signin/callback" component={LoginCallback} />
      {/* <Route exact path="/signout" component={SignOutComponent} /> */}
      <SecureRoute
        exact
        path="/fetch-data"
        render={(props) => (
          <PrivateRoute
            path="/fetch-data"
            tab={1}
            component={FetchDataComponent}
          />
        )}
      />
      <SecureRoute
        exact
        path="/home"
        render={(props) => (
          <PrivateRoute path="/home" tab={1} component={HomeComponent} />
        )}
      />
      <SecureRoute
        exact
        path="/create-user"
        render={(props) => (
          <PrivateRoute
            path="/create-user"
            tab={1}
            component={CreateUserComponent}
          />
        )}
      />
      <SecureRoute
        exact
        path="/bulk-unsubscribe-users"
        render={(props) => (
          <PrivateRoute
            path="/bulk-unsubscribe-users"
            tab={1}
            component={BulkUnsubscribeUsersComponent}
          />
        )}
      />
      <SecureRoute
        exact
        path="/details/:id"
        render={(props) => (
          <PrivateRoute
            path="/details/:id"
            tab={1}
            component={EditUserComponent}
          />
        )}
      />
      <SecureRoute
        exact
        path="/managebu"
        render={(props) => (
          <PrivateRoute
            path="/managebu"
            tab={2}
            component={ManageBusinessUnits}
          />
        )}
      />
      <SecureRoute
        exact
        path="/create-bulk-user"
        render={(props) => (
          <PrivateRoute
            path="/create-bulk-user"
            tab={1}
            component={CreateBulkUserComponent}
          />
        )}
      />
      <SecureRoute
        exact
        path="/form-data"
        render={(props) => (
          <PrivateRoute
            path="/form-data"
            tab={3}
            component={GetFormDataComponent}
          />
        )}
      />
    </Security>
  );
};
