import React from 'react';
import { Button as AntButton } from 'antd';
import PropTypes from 'prop-types';

const Button = ({ type, clickHandler, isLoading, isDisabled }) => (
	<AntButton
		type="primary"
		loading={isLoading}
		onClick={clickHandler}
		danger={type === 'delete'}
		disabled={isDisabled}
	>
		{type.toUpperCase()}
	</AntButton>
);

Button.defaultProps = {
	isLoading: false,
	isDisabled: false
};

Button.propTypes = {
	type: PropTypes.string.isRequired,
	clickHandler: PropTypes.func.isRequired,
	isDisabled: PropTypes.bool,
	isLoading: PropTypes.bool,
};

export default Button;
