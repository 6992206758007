export function listingDataModifier(tabType, data) {
	const modifiedData = [];
	switch (tabType) {
		case 'CellTab':
			// eslint-disable-next-line no-case-declarations
			let counter = 0;
			data.map((cell) => {
				cell.rolesInBusinessUnit.map((role) => {
					const roleData = {
						...cell,
						key: `cellRow${counter}`,
						McKBusinessUnitRoles: [{ ...role }],
						actionKey: counter,
					};
					delete roleData.rolesInBusinessUnit;
					modifiedData.push(roleData);
					// eslint-disable-next-line no-plusplus
					counter++;
					return '';
				});

				return '';
			});
			break;

		case 'AdminTab':
			data.map((admin, index) => {
				const adminData = {
					...admin,
					key: `adminRow${index}`,
					actionKey: index,
				};
				modifiedData.push(adminData);
				return '';
			});
			break;
		default:
			break;
	}

	return modifiedData;
}

export function deleteDataModifier(tabType, apiUrl, data) {
	const modifiedData = {
		data: [],
		apiUrl,
	};
	switch (tabType) {
		case 'CellTab':
			modifiedData.data = data;
			break;
		case 'AdminTab':
			modifiedData.apiUrl = `${apiUrl}/${data.id}`;
			break;
		default:
			break;
	}

	return { ...modifiedData };
}
