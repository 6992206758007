/* eslint-disable import/prefer-default-export */
import * as FileSaver from 'file-saver';
import * as ExcelJS from 'exceljs';

export async function ExportDataToExcel(csvData, fileName) {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('data');

    worksheet.columns = Object.keys(csvData[0]).map(key => ({ header: key, key }));
    csvData.forEach(row => {
        worksheet.addRow(row);
    });

    const buffer = await workbook.xlsx.writeBuffer();
    const data = new Blob([buffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
}

export function getFileExtension(fileName) {
    let fileNameArray = fileName.split('.');
    let extension = fileNameArray[fileNameArray.length - 1];
    return extension;
}

const sanitizeInput = (inputValue) => inputValue.trim();
export { sanitizeInput };
