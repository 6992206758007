import React from "react";
import "../../css/spinner.css";

export default function LoadingSpinner(props) {
  return (
    <div className="spinner-container">
      <div className={props.isButtonSpinner?"loading-btn-spinner":"loading-spinner"}>
      </div>
    </div>
  );
}